@import 'src/styles/variables/_sizes.scss';

.link {
  font-family: 'MaisonNeue-Medium';
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  height: 18px;
  letter-spacing: 0.16px;
  line-height: 18px;
}
