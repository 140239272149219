.companies-table {
  .status-cell {
    &.align-right {
      .pill-container {
        justify-content: flex-end;
      }
    }

    &.align-left {
      .pill-container {
        justify-content: flex-start;
      }
    }
  }
  .pill-container {
    display: flex;

    .status-pill {
      white-space: nowrap;
    }
  }
}
