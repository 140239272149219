@import '~styles/variables/_colors.scss';

.measure-table {
  margin: 40px 0;
  font-family: 'Maison Neue';
  color: $qm-color-text;

  > h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 6px 0;
  }

  > .description {
    width: 424px;
    font-size: 14px;
    font-weight: 500;
  }

  input[type='file'] {
    display: none;
  }

  .qm-button {
    width: auto;
    height: auto;
    display: inline-flex;
    padding: 7px 16px;
    margin: 13px 0;
    font-weight: 500;
    height: 32px;
    cursor: pointer;
  }

  .error {
    color: $qm-color-error;
    display: flex;
    margin: 15px 0;

    svg {
      width: 32px;
      height: 32px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      align-self: center;
      margin-left: 12px;
    }
  }

  .table-container {
    overflow: auto;
    margin: 30px 0;
    font-size: 16px;

    input {
      font-size: 16px;
    }

    table {
      font-size: 16px;
      font-weight: 500;

      th {
        text-align: left;
        line-height: 22px;
        padding: 5px 30px 5px 0;
        white-space: nowrap;
      }

      tbody {
        td {
          padding: 10px 32px 10px 0;
          vertical-align: middle;

          .input-placeholder {
            position: relative;

            input {
              min-width: 60px;
            }

            .select-input {
              margin-top: 2px;
              min-width: 110px;

              input {
                padding: 0;
              }
            }

            &.error-field {
              input,
              .select-input {
                border: 1px solid $qm-color-delete;
              }

              .error-icon {
                position: absolute;
                top: 0;
                right: 12px;
                height: 100%;
                display: flex;
                align-items: center;

                svg {
                  background-color: $qm-color-input;
                }
              }
            }
          }
        }
      }
    }
  }

  .custom-container {
    margin-top: 16px;
  }

  .custom-measure-field {
    display: table;
    font-size: 16px;
    font-weight: 500;

    input {
      font-size: 16px;
    }

    .row {
      display: table-row;
      .cell {
        display: table-cell;
        width: 140px;
        padding: 5px 20px 5px 0;

        &.dropdown {
          vertical-align: middle;

          .select-input {
            min-width: 150px;
            margin-top: 2px;

            input {
              padding: 0;
            }
          }
        }

        &:last-child {
          width: auto;
          vertical-align: middle;
        }
      }
    }
    .delete-button {
      color: $qm-color-error;
      display: flex;
      cursor: pointer;

      span {
        align-self: center;
        margin-left: 8px;
      }
    }
  }
}
