.decision-container {
  margin-top: 24px;
  margin-right: 32px;
  padding: 16px;
  border: solid 1px #f1c21b;
  border-radius: 8px;

  .alert-icon {
    width: 20px;
    height: 20px;
    color: #f1c21b;
    margin-right: 8px;
  }

  .decision-message {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 500;
  }

  .decision-action-buttons {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
