@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.assign-inspection-plan-to-an-asset-table {
  .date-published {
    vertical-align: center;
  }

  .action-cell {
    width: 40px;
  }

  .assign-table-action-button {
    border: none;
    background-color: $qm-gray-medium;
    width: 40px;
    height: 40px;
    padding: 0;

    svg {
      width: 22px;
      height: 22px;
    }

    &.unassign {
      svg {
        color: $qm-color-delete;
      }
    }

    &.assign {
      svg {
        color: $qm-color-primary;
      }
    }
  }
}
