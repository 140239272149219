@import 'src/styles/variables/_colors.scss';
@import '~styles/variables/sizes';

.inspection-types-form {
  .form-row {
    margin-bottom: $qm-margin-l;
  }

  h4.title {
    font-family: 'MaisonNeue-Medium';
    font-size: 20px;
    font-weight: 500;
    margin-bottom: $qm-margin-m;
  }

  .form-info {
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    color: $qm-color-text-secondary;
    font-weight: 500;
    line-height: 18px;
    max-width: 424px;
    margin-bottom: 1em;
  }

  .qm-input-container,
  .status-input-container,
  .type-input-container {
    max-width: 440px;
  }

  .qm-input,
  .select-input {
    &:not(.is-invalid) {
      margin-bottom: 1px;
    }
  }

  .automatic-decision {
    margin-bottom: 24px;
  }

  .usage-decisions-container {
    margin-bottom: $qm-margin-l;

    .group-header {
      display: flex;
      align-items: center;
      column-gap: 32px;
      margin-bottom: $qm-margin-m;

      .title {
        margin-bottom: 0;
      }
    }

    .remove-group {
      width: 32px;
      height: 32px;
    }

    .is-invalid-decision {
      padding: 8px;
      border: solid 1px $qm-color-error;
      border-radius: 4px;
    }
  }
  .error-bag {
    margin-top: 8px;
  }
  .error-bag .invalid-feedback {
    margin-bottom: 1px;
  }
  .table {
    display: table;
    width: 100%;

    .table-row {
      display: table-row;

      &.table-header {
        .table-cell {
          padding-bottom: 4px;
          font-family: 'MaisonNeue-Medium';
          font-size: 16px;
          font-weight: 500;
          color: $qm-color-text;
        }
      }
    }

    .table-cell {
      display: table-cell;
      padding: 8px 0;
      line-height: 22px;
      vertical-align: top;
      width: 21%;

      &.delete-cell {
        width: 50px;
      }

      &.notify-cell {
        width: 30%;
      }

      .default-value {
        display: flex;
        margin-top: 32px;
      }

      span {
        padding-left: $qm-padding-ms;
      }

      .cell-container {
        margin-right: $qm-margin-s;
        min-width: 200px;

        .input-info {
          height: 16px;
          justify-content: flex-end;
        }

        .select-input {
          margin-top: 22px;
        }

        &.notify {
          display: flex;
          flex-wrap: wrap;

          .select-input {
            width: 100%;
          }

          .is-invalid {
            .qm-select__control {
              background-position: 96%;
            }
          }
        }
      }
    }
  }
  // TODO remove this after we have proper icon buttons
  .trash-button {
    color: #a2191f;
    cursor: pointer;
    background: transparent;
    border: none;
    font-family: 'Maison Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: $qm-button-height;
    margin-left: $qm-margin-s;
    margin-top: 20px;

    img {
      margin-right: 8px;
    }
  }
  .medium-button {
    margin-top: $qm-margin-m;
  }
}
