@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.settings-input-modal {
  font-family: MaisonNeue-Medium;

  .body {
    padding: 0 40px;
  }

  .qm-language-select {
    margin-bottom: $qm-margin-xs;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
  }

  .link-button {
    color: $qm-color-primary;
    font-family: MaisonNeue-Medium;
    font-size: 14px;
    font-weight: 500;
    height: 18px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
