@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-dropdown-menu {
  &-items-container {
    padding-top: $qm-border-radius-m;
    padding-bottom: $qm-border-radius-m;

    &:not(.has-icons) {
      .menu-item-icon {
        display: none;
      }
    }
  }

  &-item {
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: $qm-color-text;
    cursor: pointer;

    padding: $qm-margin-xs $qm-margin-s;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover,
    &:focus {
      background-color: $qm-color-row-hover;
    }

    .menu-item-icon {
      width: 1em;
      display: flex;
      align-items: center;

      svg {
        width: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }

    &.disabled {
      cursor: not-allowed;
      color: $qm-color-text-tertiary;

      svg {
        opacity: 0.4;
      }

      &:hover,
      &:focus {
        background-color: unset;
      }
    }
  }
}
