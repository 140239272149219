@import '~styles/variables/colors';

.tabs-container {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;

  &.tabs-separator {
    padding-bottom: 0;
    border-bottom: 1px solid $qm-gray-lighter;
  }
}
