@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.qm-input-container {
  input {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
    color: $qm-color-text-secondary;
  }

  .input-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 6px;
  }

  .form-label {
    margin-bottom: 0px;
  }

  .chars-left {
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 18px;
    color: $qm-color-text-tertiary;
  }

  .qm-input {
    border-radius: $qm-border-radius-m;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    padding: $qm-padding-s;
    padding-left: $qm-padding-ms;
    height: $qm-input-height;
    background-color: $qm-color-input !important;
    width: 100%;
    line-height: 24px;
    transition: border 0.1s;

    &:hover,
    &:focus {
      border-color: $qm-color-selected;
    }

    &.is-invalid {
      background: url('../../../assets/images/alert-circle.svg');
      background-size: 20px;
      background-position: 96%;
      background-repeat: no-repeat;

      border: 1px solid $qm-color-error;

      &[type='number'] {
        background-position: right 32px bottom 50%;
      }
    }

    &::placeholder {
      color: $qm-color-bg;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &.with-left-insert {
      padding-left: 44px;
    }

    &.with-right-insert {
      padding-right: 44px;
    }
  }

  .error-bag {
    margin-top: 8px;
    text-align: start;
  }

  .insert {
    margin-top: 12px;
    position: absolute;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .left-insert {
    margin-left: 12px;
  }

  .right-insert {
    margin-right: -12px;
  }
}
