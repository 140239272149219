@import '~styles/variables/sizes';

.assign-inspection-plan-list-page {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filter-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .assign-inspection-plan-list-header {
    margin-bottom: $qm-margin-l;
    padding-bottom: 0;

    h2 {
      width: 50%;
    }
  }

  .margin-bottom {
    margin-bottom: $qm-margin-s;
  }

  p {
    font-family: MaisonNeue-Book;
    width: 50%;
  }

  .search-container {
    width: 200px;
    margin-bottom: 16px;
  }
}
