@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-dropdown {
  $arrow-half-width: 6px;
  $bg-color: $qm-white;
  $border-size: 1px;
  $arrow-offset: -$arrow-half-width;
  $border-color: $qm-gray-lighter;

  position: relative;

  background-color: $bg-color;
  color: $qm-color-text;
  border-radius: $qm-border-radius-s;
  outline: 0;

  border-radius: $qm-border-radius-m;
  border: $border-size solid $border-color;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

  .tippy-content {
    position: relative;
    z-index: 1;
  }

  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;

    &:before {
      bottom: $arrow-offset - $border-size;
      left: -$border-size;
      border-width: ($arrow-half-width + $border-size)
        ($arrow-half-width + $border-size) 0;
      border-top-color: $border-color;
      transform-origin: center top;
    }

    &:after {
      bottom: $arrow-offset;
      left: 0;
      border-width: $arrow-half-width $arrow-half-width 0;
      border-top-color: $bg-color;
      transform-origin: center top;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;

    &:before {
      top: $arrow-offset - $border-size;
      left: -$border-size;
      border-width: 0 ($arrow-half-width + $border-size)
        ($arrow-half-width + $border-size);
      border-bottom-color: $border-color;
      transform-origin: center bottom;
    }

    &:after {
      top: $arrow-offset;
      left: 0;
      border-width: 0 $arrow-half-width $arrow-half-width;
      border-bottom-color: $bg-color;
      transform-origin: center bottom;
    }
  }

  &[data-placement^='left'] > .tippy-arrow {
    right: 0;

    &:before {
      border-width: ($arrow-half-width + $border-size) 0
        ($arrow-half-width + $border-size) ($arrow-half-width + $border-size);
      border-left-color: $border-color;
      right: $arrow-offset - $border-size * 2;
      top: -$border-size;
      transform-origin: center left;
    }

    &:after {
      border-width: $arrow-half-width 0 $arrow-half-width $arrow-half-width;
      border-left-color: $bg-color;
      right: $arrow-offset - $border-size;
      transform-origin: center left;
    }
  }

  &[data-placement^='right'] > .tippy-arrow {
    left: 0;

    &:before {
      left: $arrow-offset - $border-size;
      top: -$border-size;
      border-width: ($arrow-half-width + $border-size)
        ($arrow-half-width + $border-size) ($arrow-half-width + $border-size) 0;
      border-right-color: $border-color;
      transform-origin: center right;
    }

    &:after {
      left: $arrow-offset;
      border-width: $arrow-half-width $arrow-half-width $arrow-half-width 0;
      border-right-color: $bg-color;
      transform-origin: center right;
    }
  }

  .tippy-arrow {
    width: $arrow-half-width * 2;
    height: $arrow-half-width * 2;
    color: $bg-color;

    &:before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }

    &:after {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }
}
