@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';
.qm-question-group {
  .question-group-details,
  .question-group-order {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .question-group-order {
    > * + * {
      margin-left: $qm-margin-xs;
    }
  }

  .question-group-details {
    > * + * {
      margin-left: $qm-padding-ms;
    }
  }

  .questions-table {
    grid-template-columns: [id] fit-content(5em) [question] auto [type] auto [sample-size] auto [toggle] 24px [end];
    grid-auto-rows: auto;
    display: grid;

    .table-row {
      display: contents;

      &.question-summary {
        .id-cell {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    p {
      max-width: 40ch;
    }

    .table-cell {
      padding-right: $qm-margin-m;
      &:last-child {
        padding-right: 0;
      }

      &.align-right {
        text-align: right;
        justify-content: flex-end;
      }

      &.align-center {
        text-align: center;
        justify-content: space-around;
      }

      &.edit-cell {
        display: none;
        svg {
          vertical-align: middle;
        }
      }
    }

    .table-header {
      .table-cell {
        border-bottom: 1px solid $qm-gray-lighter;
        font-family: 'MaisonNeue-Medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $qm-color-text-tertiary;
        padding-bottom: $qm-margin-xs;
      }
    }
  }
  &.show-header-dependency {
    .questions-table {
      grid-template-columns: [id] fit-content(5em) [question] auto [type] auto [dependency] auto [sample-size] auto [toggle] 24px [end];
    }
  }

  &.editable {
    .questions-table {
      grid-template-columns: [edit] fit-content(1em) [id] fit-content(5em) [question] auto [type] auto [sample-size] auto [toggle] 24px [end];

      .edit-cell {
        display: block;
      }
    }

    &.show-header-dependency {
      .questions-table {
        grid-template-columns: [edit] fit-content(1em) [id] fit-content(5em) [question] auto [type] auto [dependency] auto [sample-size] auto [toggle] 24px [end];
      }
    }
  }

  .question-actions {
    display: flex;
    flex-direction: column;
    gap: $qm-margin-xs;

    .action-group {
      position: relative;
      .extra {
        display: none;
        position: absolute;
      }
      &.down {
        .extra {
          bottom: -100%;
        }
      }

      &.up {
        .extra {
          top: -100%;
        }
      }

      &:hover {
        .extra {
          display: block;
        }
      }
    }
  }

  .qm-question-form,
  .qm-question-detail,
  .qm-answer-detail {
    &:last-child {
      .table-row:last-child {
        .table-cell {
          padding-bottom: 0;
        }
      }
    }

    &:nth-child(2) {
      .table-cell:not(.body-cell) {
        padding-top: $qm-margin-m;
      }
    }
  }
}

@media print {
  .qm-question-group {
    border: none;
    padding-left: 0;
    padding-right: 0;

    .qm-toggle {
      display: none;
    }
  }
}
