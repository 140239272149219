@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-date-range-picker-container {
  .separator {
    color: $qm-color-text-tertiary;
    user-select: none;
    flex: 0;
    width: 1ch;
  }

  .qm-date-range-clear {
    cursor: pointer;
    color: $qm-color-text-tertiary;
  }

  .input-row {
    display: flex;
    align-items: center;
    gap: 4px;

    border-radius: $qm-border-radius-m;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    height: $qm-input-height;
    background-color: $qm-color-input;
    transition: border 0.1s;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    line-height: 22px;
    padding-right: $qm-padding-ms;

    &:hover,
    &:focus-within {
      border-color: $qm-color-selected;
    }
  }

  &.inline {
    .input-row {
      display: inline-flex;
      width: auto;
    }
  }

  input.qm-date-range-start-input {
    padding-left: $qm-padding-ms;
  }

  input {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;

    height: $qm-input-height;
    color: $qm-color-text-secondary;
    background-color: $qm-color-input;
    border: none;
    padding-top: $qm-padding-s;
    padding-bottom: $qm-padding-s;

    flex: 1;
    min-width: 10ch;

    &[disabled] {
      color: $qm-color-text-tertiary;
      cursor: not-allowed;
    }

    &::-webkit-clear-button {
      display: none;
      -webkit-appearance: none;
    }
  }

  &.disabled {
    .input-row {
      color: $qm-color-text-tertiary;
      border-color: transparent;
      cursor: not-allowed;
    }
  }

  &.is-invalid {
    .input-row {
      border-color: $qm-color-error;
    }
  }
}
