$qm-padding-l: 60px;
$qm-padding-m: 40px;
$qm-padding-ms: 16px;
$qm-padding: 32px;
$qm-padding-s: 12px;

$qm-border-radius-s: 4px;
$qm-border-radius-m: 6px;

$qm-margin-xs: 8px;
$qm-margin-s: 12px;
$qm-margin-m: 24px;
$qm-margin-l: 48px;

$qm-input-height: 44px;
$qm-button-height: 48px;
$qm-button-height-m: 32px;
$qm-textarea-height: 200px;
