@import "~styles/variables/sizes";
@import "~styles/variables/colors";

.inspections-list-page {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filter-container {
    padding-top: 0;
    padding-bottom: 30px; // Hack to look like other pages
    display: flex;
    // align-items: flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 16px;
    z-index: 20;
  }

  .filter {
    display: flex;
    flex-direction: row;
    // justify-self: flex-end;
    justify-content: center;
    align-items: center;
    line-height: 22px;

    .filter-select {
      margin-left: 8px;
      width: 146px;
    }

    .filter-date-range {
      margin-left: 8px;
    }
  }

  .errors-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .inspections-list-header {
    margin-bottom: $qm-margin-l;
    padding-bottom: 0;

    h2 {
      width: 50%;
    }
  }

  .search-container {
    flex-grow: 1;
    min-width: 150px;

    .search-input-container {
      max-width: 250px;
    }

    .input-info {
      display: none;
    }
  }

  .actions-container {
    display: flex;
    flex-direction: row;

    > * + * {
      margin-left: $qm-margin-m;
    }

    .menu-item-icon {
      color: $qm-color-primary;
    }
  }

  .action-banner-container {
    // If editing this also update .inspections-table.selected-within top
    align-items: center;
    color: $qm-white;
    padding: $qm-padding-ms $qm-padding-m;
    background-color: $qm-color-selected;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    * {
      font-size: 16px;
    }

    .link-button {
      color: $qm-white;
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      text-underline-offset: 1px;
    }

    .actions {
      margin-left: auto;
      display: flex;

      .qm-date-picker-overlay {
        right: 0;
      }

      > * + * {
        margin-left: 32px;
      }
    }
  }

  .inspections-table.selected-within {
    .table-header {
      .table-cell {
        // There is currently no way to automatically determine top;
        // Keep this in sync with .action-banner-container height
        $actionBannerContentHeight: 22px;
        top: $qm-padding-ms * 2 + $actionBannerContentHeight;
      }
    }
  }
}

.qm-modal.inspection-list--inspector-modal {
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .input-info {
      display: none;
    }

    .qm-date-picker-container {
      width: 180px;
    }
  }
}

.qm-modal.inspection-list--date-modal {
  width: 20em;
  .body {
    padding: 0 $qm-padding-m;
  }
}

.qm-modal.inspection-list--bulk-confirm {
  max-height: 95vh;

  .body {
    padding: 0 $qm-padding-m;

    .applied-changes-label {
      .applied-value,
      .applied-inspections-count {
        font-weight: 600;
      }
    }

    .applied-changes-list {
      margin-top: 0.5em;

      li:not(:first-child) {
        margin-top: 0.25em;
      }
    }
  }
}
