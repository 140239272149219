/* == WARNING / TODO ==
  The default 'react-image-gallery' styles are applied globally
  by the import in pages/admin/assets/asset-details/AssetsDetailPage.js

  This style will need updating when the offending import is removed.
*/

@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-image-gallery {
  $thumbnail-height: 124px;
  $thumbnail-border-width: 2px;

  .image-gallery-content {
    display: flex;
    flex-direction: column;

    .image-gallery-slide-wrapper {
      flex: 1;

      .gallery-nav {
        width: 40px;
        height: 40px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;
        border-radius: 50%;
        border: 1px solid #e0dfe0;
        cursor: pointer;

        svg {
          color: $qm-color-primary;
        }

        &.right {
          right: -11px;
        }

        &.left {
          left: -11px;
        }
      }

      .image-gallery-slides {
        border-radius: $qm-border-radius-s;
        overflow: hidden;
      }

      .image-gallery-swipe {
        height: 100%;

        height: 400px;
        border-radius: $qm-border-radius-s;
        overflow: hidden;
        display: flex;
        align-items: center;

        .image-gallery-slides {
          line-height: 0;
          position: relative;
          white-space: nowrap;
          height: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
          flex: 1;

          .image-gallery-slide {
            > div {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .image-gallery-thumbnails-wrapper {
      flex: 0 0;
      height: $thumbnail-height;

      .image-gallery-thumbnails {
        padding: 0;
        margin-top: $qm-margin-m;

        .image-gallery-thumbnails-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: $qm-margin-m;
          cursor: auto;

          .image-gallery-thumbnail {
            height: 82px;
            max-width: 128px;
            border: none;
            border-radius: $qm-border-radius-s;
            cursor: pointer;

            display: flex;
            justify-content: center;
            justify-items: center;
            overflow: hidden;
            flex: 1;
            position: relative;

            &::after {
              border-radius: $qm-border-radius-s;
              box-shadow: inset 0 0 0 $thumbnail-border-width $qm-color-primary;
              content: '';
              display: block;
              height: 100%;
              position: absolute;
              top: 0;
              width: 100%;
              opacity: 0;
              transition: opacity 0.1s linear;
            }

            &.active {
              border: none;
              &::after {
                opacity: 1;
              }
            }

            .image-gallery-thumbnail-inner {
              width: 100%;
              height: 100%;
              overflow: hidden;

              img.image-gallery-thumbnail-image {
                object-fit: cover;
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.thumbnails-center {
    .image-gallery-content
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails
      .image-gallery-thumbnails-container {
      justify-content: center;
    }
  }
}
