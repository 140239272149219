@import '~styles/variables/colors';

ul.timeline-subitem {
  position: relative;
  padding: 32px 0 0 0;

  &.empty-subitem {
    color: $qm-color-text-secondary;
    p {
      font-size: 14px;
      font-family: MaisonNeue-Medium;
    }
  }

  &::after {
    width: 1px;
    display: block;
    background: $qm-gray-lighter;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: -44px;
  }

  &:last-of-type {
    padding-bottom: 72px;
  }

  li {
    position: relative;
    padding: 4px 0 0 12px;

    &::before {
      display: block;
      position: absolute;
      top: 15px;
      left: -44px;
      content: '';
      width: 48px;
      height: 1px;
      background: $qm-gray-lighter;
    }

    .subitem-icon {
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      content: '';
      top: 13px;
      left: 0;
      background: $qm-gray-lighter;

      img {
        width: 24px;
        height: 24px;
      }

      &.completed,
      &.failed,
      &.custom-icon {
        width: 24px;
        height: 24px;
        top: 3px;
        left: -19px;
        background: none;
      }

      &.custom-icon {
        background: #ffffff;
        border: $qm-gray-lighter 1px solid;
      }

      .completed-item-icon,
      .failed-item-icon {
        border-radius: 50%;
        padding: 4px;
      }

      .completed-item-icon {
        background: $qm-color-success;
      }

      .failed-item-icon {
        background: $qm-color-error-light;
      }
    }
  }
}
