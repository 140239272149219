@import '~styles/variables/colors';

.inspection-ready-modal {
  max-width: 600px;
  width: 60vw;
  max-height: 500px;

  .inspection-ready-form {
    padding: 0 40px;
    p {
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .qm-input {
      width: 130px;
    }

    .qm-date-picker-container {
      .qm-input {
        width: 180px;
      }
    }

    .qm-input-container {
      margin-top: 24px;
    }

    .form-description {
      margin-top: 8px;
      color: $qm-color-text-tertiary;
    }
  }

  .error-bag {
    margin-top: 16px;
  }
}
