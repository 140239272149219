@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-named-avatar {
  display: flex;
  flex-direction: row;
  gap: $qm-margin-xs;

  .avatar {
    display: flex;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;

    .title {
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;
      font-family: 'MaisonNeue-Medium';
      color: $qm-color-text;
    }

    .subtitle {
      font-family: 'MaisonNeue-Book';
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      color: $qm-color-text-secondary;
    }
  }

  &.large {
    .title {
      font-family: 'MaisonNeue-Bold';
      font-weight: bold;
    }
  }

  &.clickable {
    cursor: pointer;
  }
}
