@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-file {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }

  .icon {
    color: $qm-white;
    background-color: rgba($qm-color-selected, 0.8);
    border-radius: $qm-border-radius-s;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;

    svg {
      font-size: 24px;
    }

    .remove-button {
      color: $qm-color-delete;
      position: absolute;
      top: -6px;
      right: -6px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  .name {
    color: $qm-color-selected;
    font-family: MaisonNeue-Medium;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    max-width: 144px;
    word-wrap: break-word;
    margin-top: $qm-margin-xs;
  }
}
