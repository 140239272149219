.unsubscribe-page {
  width: 100%;
  height: 100%;
  padding: 64px;

  .message {
    text-align: center;
    font-size: 20px;
    min-width: 400px;
    margin: 0;
    padding: 0;
  }
}
