@import '../../styles/variables/colors';

.menuContainer {
  font-family: 'Maison Neue';
  $animation-duration: 100ms;

  .menuButton {
    display: block;
    color: $qm-color-text;
    font-size: 16px;
    white-space: nowrap;
    text-decoration: none;
    padding: 13px 26px;
    transition: color $animation-duration, transform $animation-duration;
    position: relative;
    transform: translateX(0);
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }

    &::after {
      content: '';
      position: absolute;
      left: -2px;
      top: 0;
      bottom: 0;
      width: 32px;
      border-left: 2px solid $qm-color-selected;
      background-image: linear-gradient(
        to right,
        rgba($qm-color-selected, 0.09) 0%,
        rgba($qm-color-selected, 0) 100%
      );
      opacity: 0;
      transition: opacity $animation-duration;
    }

    &.selected,
    &:hover,
    &:focus {
      color: $qm-color-selected;
      transform: translateX(2px);

      &::after {
        opacity: 1;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }
}
