@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

body {
  &.qm-modal-open {
    overflow: hidden;
  }
}

.qm-modal-portal {
  .qm-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;

    .qm-modal {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      background-color: $qm-white;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

      .header {
        display: flex;
        flex-direction: column;
        gap: $qm-margin-m;
        padding: $qm-padding-m;
        padding-bottom: 0;
        margin-bottom: $qm-margin-m;

        .title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          h2 {
            color: $qm-color-text;
            font-family: 'MaisonNeue-Medium';
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
          }

          .close-button {
            width: 18px;
            height: 18px;
            border: none;
            background: transparent;
            padding: 0;
            margin: 0;

            svg {
              color: $qm-color-primary;
              cursor: pointer;
            }
          }
        }
      }

      .body {
        flex: 1;
        overflow: auto;
      }

      .footer {
        flex: 0;
        padding: $qm-padding-m;
        padding-top: $qm-padding;
        padding-bottom: $qm-padding;

        .qm-button {
          height: 40px;
        }
      }
    }
  }
}
