@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.settings-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 240px;

  .settings-menu-button {
    padding: $qm-padding-s $qm-padding-ms;
    margin-left: -$qm-padding-s;

    color: $qm-color-text;
    text-decoration: none;
    font-family: MaisonNeue-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    border-radius: $qm-border-radius-s;

    &.selected {
      color: #fff;
      background-color: $qm-color-selected;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}
