@import "~styles/variables/colors";

.inspection-usage-decisions-override {
  button {
    display: inline-flex;
    background: $qm-color-error;
    font-weight: 600;

    &:hover {
      background: darken($qm-color-error, 10%);
    }
  }

  .ud-group {
    margin-top: 1em;

    h5 {
      font-size: 1.25rem;
      font-family: "MaisonNeue-Book";
      font-weight: 300;
    }

    .ud-group-actions-buttons {
      button {
        margin-right: 0.5em;
      }
    }
  }
}
