@import 'styles/variables/_colors.scss';
.settings-answers-form {
  display: grid;
  grid-template-columns: 250px auto;
  align-items: flex-start;
  gap: 24px;

  .link-button {
    color: $qm-color-primary;
    font-family: MaisonNeue-Medium;
    font-size: 14px;
    font-weight: 500;
    height: 18px;

    &:hover {
      text-decoration: underline;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .label-container {
    display: flex;
    margin-bottom: -16px;
    p:first-of-type {
      width: 180px;
      margin-right: 24px;
    }
  }

  .row {
    display: flex;

    .medium-button {
      margin-left: 24px;
      transform: translateY(30px);
    }

    .link-button {
      margin-left: 24px;
      transform: translateY(34px);

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .answer-data {
    display: flex;
    gap: 24px;
    align-items: flex-start;

    .select-action {
      margin-top: 6px;
      margin-left: -6px;
    }

    .error-bag .invalid-feedback {
      margin-top: 6px;
      margin-bottom: 0;
      width: 180px;
    }
  }

  .answers-name {
    width: 180px;
  }

  .actions-name {
    height: 44px;
    width: 180px;
  }

  .answers-group.answers-group.answers-group {
    width: 250px;
  }
}
