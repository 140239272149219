@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.qm-notes-modal {
  min-width: 576px;

  .body {
    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;

    p {
      margin-bottom: $qm-margin-m;
    }

    .textarea-info .chars-left {
      text-align: right;
    }
  }

  .header {
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    gap: $qm-padding;
  }
}
