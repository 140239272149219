@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-answer-form {
  .qm-input-container,
  .qm-textarea-container,
  .select-input {
    width: 400px;
  }

  .question-cell {
    white-space: pre-wrap;
  }

  .form-label,
  .attribute {
    margin-top: 16px;
  }

  &.hidden {
    .table-cell {
      color: $qm-color-text-tertiary;
    }
  }

  a {
    text-decoration: none;
  }

  .field-description {
    margin-top: $qm-margin-xs;
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .attribute {
    display: flex;
    flex-direction: row;

    .label {
      &::after {
        content: ': ';
        white-space: pre;
      }
    }

    .value {
      display: flex;
      flex-direction: column;
      color: $qm-color-text;
      font-family: MaisonNeue-Bold;
      font-weight: bold;
    }
  }

  .defective-units-container {
    gap: $qm-margin-s;

    .qm-input-container {
      width: 140px;
    }
  }

  .remove-button {
    svg {
      color: $qm-color-delete;
    }
  }
}
