@import 'src/styles/variables/_colors.scss';

.work-objects-import-page {
  font-family: MaisonNeue-Medium;

  h2 {
    font-size: 42px;
    margin-bottom: 16px;
  }

  > p {
    max-width: 408px;
  }

  > .document {
    display: inline-block;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 64px;

    a {
      text-decoration: none;
    }

    .iconWrapper {
      padding: 1em;
      background-color: $qm-color-selected;
      display: inline-block;
      border-radius: 5px;
      margin: 0.8em;

      > svg {
        color: white;
        font-size: 2em;
      }
    }
  }

  .done {
    > div {
      margin: 40px 0;
    }

    .icon {
      display: inline-block;
      padding: 11px 10px 9px 14px;
      border-radius: 100%;
      margin-right: 15px;
    }

    .success {
      color: #24a148;
      font-size: 20px;

      .icon {
        background-color: #42be65;
      }
    }

    .error {
      .icon {
        background-color: $qm-color-delete;
        vertical-align: top;
      }
      .messages {
        display: inline-block;
        width: 100%;
        max-width: calc(100% - 70px);

        p:first-child {
          color: $qm-color-error;
          font-size: 20px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 12px;
        }

        p {
          color: $qm-color-text-secondary;
          max-width: 100%;
        }
      }
    }
  }
}
