@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.selectable-table-container {
  .selectable-table {
    &.selected-within {
      .table-header {
        .table-cell {
          top: 40px;
        }
      }
    }

    .table-cell {
      &.select-cell {
        display: table-cell;
        cursor: initial;
        width: 0;
        padding: 0;

        .checkbox-wrapper {
          padding: $qm-padding;
          padding-left: $qm-padding-m;
        }

        .qm-checkbox {
          padding: $qm-padding-s;
          margin-left: -$qm-padding-s;
          width: $qm-input-height;
        }
      }
    }

    .table-header {
      .table-cell.select-cell {
        padding-bottom: 0;
        padding-top: 0;
        padding-left: $qm-padding-m;
        padding-right: $qm-padding;

        .qm-checkbox {
          padding: 0;
          justify-content: center;
        }
      }
    }

    .table-row {
      &.row-selected {
        background: $qm-color-row-selected;
      }
    }
  }
  .selection-info-container {
    display: flex;
    height: 40px;
    font-size: 16px;
    align-items: center;
    justify-content: space-between;
    color: $qm-white;
    padding: $qm-padding-ms $qm-padding-m;
    background-color: $qm-color-selected;
    position: sticky;
    top: 0;
    z-index: 10;

    .link-button {
      font-size: 16px;
      color: $qm-white;
      font-family: 'MaisonNeue-DemiBold';
      font-weight: 600;
      text-underline-offset: 1px;
    }

    .actions {
      display: flex;
      column-gap: 32px;
    }
  }
}
