@import 'styles/variables/_colors';

.question-actions {
  .move-top-icon,
  .move-up-icon,
  .move-down-icon,
  .move-bottom-icon,
  .link-icon,
  .unlink-icon {
    color: $qm-color-primary;
  }

  .delete-icon {
    color: $qm-color-delete;
  }

  .move-down-icon,
  .move-bottom-icon {
    transform: rotate(180deg);
  }

  .question-actions-trigger-button {
    padding: 0;
    background-color: #ecf2ff;
    width: 20px;
    height: 20px;

    svg {
      color: $qm-color-primary;
      width: 14px;
      height: 14px;
    }
  }
}
