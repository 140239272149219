@import '~styles/variables/colors';

.inspection-types-table {
  .secondary-text {
    color: $qm-color-text-secondary;
    font-family: 'MaisonNeue-Book';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  .status-cell {
    &.align-right {
      .pill-container {
        justify-content: flex-end;
      }
    }

    &.align-left {
      .pill-container {
        justify-content: flex-start;
      }
    }
  }
  .pill-container {
    display: flex;

    .status-pill {
      white-space: nowrap;
    }
  }
}
