@import '~styles/variables/_sizes';
@import '~styles/variables/_colors';

.inspection-plans-detail-page {
  h1 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: $qm-margin-s;
  }

  h2 {
    color: $qm-color-text;
    font-family: MaisonNeue-DemiBold;
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
  }

  .plan-notes {
    max-width: 600px;
    padding-top: 0;
    margin-top: -20px;
    padding-bottom: 20px;
  }

  .plan-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .details {
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .id {
          color: $qm-color-text-tertiary;
          font-family: MaisonNeue-Book;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
        }

        > .pill {
          margin-left: $qm-margin-s;
        }
      }
    }

    .actions {
      margin-left: auto;
      display: flex;
      gap: $qm-margin-m;
    }
  }

  .plan-description {
    p {
      color: $qm-color-text;
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      max-width: 440px;
    }
  }

  .plan-details {
    font-size: 16px;
    line-height: 22px;
    font-family: MaisonNeue-Book;

    > * + * {
      margin-top: $qm-margin-xs;
    }

    .attribute {
      display: flex;
      flex-direction: row;

      .label {
        color: $qm-color-text-secondary;
        font-weight: 300;

        &::after {
          content: ': ';
          white-space: pre;
        }
      }

      .value {
        display: flex;
        flex-direction: column;
        color: $qm-color-text;
        font-family: MaisonNeue-Bold;
        font-weight: bold;
      }
    }
  }

  .group-unlinked-icon {
    margin-left: $qm-margin-s;
  }

  .question-groups {
    margin-bottom: $qm-padding;

    & > * + * {
      margin-top: $qm-margin-m;
    }
  }

  .broken-link-question {
    display: flex;
  }

  .qm-question-detail.is-open {
    .id-cell {
      grid-row-end: span 2;
    }
  }

  .container-m + .container-m {
    padding-top: 0;
  }
}
