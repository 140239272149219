@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.work-objects-form {
  h3 {
    font-family: 'MaisonNeue-Medium';
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 8px 0;
  }
  .qm-input-container,
  .select-input {
    width: 280px;
    line-height: 24px;
  }

  .form-row {
    margin-bottom: 32px;
  }

  .quantity-container,
  .deadline-container {
    .qm-input-container {
      max-width: 190px;
    }
  }

  .qm-resource-select-button-container {
    width: 412px;
  }

  .deadline-container {
    .description {
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      color: $qm-color-text-tertiary;
    }
  }

  .field-description {
    max-width: 424px;
    margin-top: $qm-margin-xs;
  }

  .custom-field-container {
    .custom-field-item {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      margin-top: 32px;

      .delete-custom-field {
        margin-top: 20px;
      }
    }

    textarea {
      resize: vertical;
    }

    .select-input {
      width: 275px;
    }
    .error-bag .invalid-feedback {
      margin-bottom: 1px;
    }
    .qm-input {
      &:not(.is-invalid) {
        margin-bottom: 1px;
      }
    }
  }

  .workflow-container {
    .qm-resource-select-button-container {
      margin-top: 32px;
    }
    .workflow-source,
    .workflow-destination {
      max-width: 348px;
    }
  }

  .add-custom-field {
    margin-top: 40px;
  }

  .switch-btn {
    margin-top: 8px;
    color: $qm-color-text-link;
    background: none;
    border: none;
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
  .submit-container {
    margin-top: 72px;
  }
  .error-bag {
    margin-top: 8px;
    min-width: 220px;
  }
  .form-info {
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    color: $qm-color-text-secondary;
    font-weight: 500;
    line-height: 18px;
    max-width: 424px;
    margin-top: 16px;
  }
}
