@import "~styles/variables/_colors";
@import "~styles/variables/_sizes";

.qm-defect {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .pill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    border-radius: 50%;
    background-color: $qm-gray-lighter;

    &.minor {
      background-color: #fbedba;
    }

    &.major {
      background-color: #ffdabf;
    }

    &.critical {
      background-color: #fec9cc;
    }

    &.severe {
      background-color: $qm-color-error;
      color: #fff;
    }

    &.informational {
      background-color: $qm-gray-lighter;
    }

    &.functional {
      background-color: #dde7fd;
    }

    &.is-accepted {
      background: $qm-color-success;
    }

    &.is-rejected {
      background: $qm-color-error-light;
    }
  }

  .description {
    color: $qm-color-text;
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  &.count-digits-3 .pill .value {
    // font-size: 0.75em;
    transform: scaleX(0.85);
  }
}
