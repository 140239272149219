@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.assets-modal {
  max-width: 900px;
  width: 80vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .qm-input {
      width: 330px;
    }
  }

  .pagination-container {
    margin-left: auto;
    width: max-content;
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-row {
      &:hover {
        background-color: #f5f5f5;
      }
    }

    .table-cell {
      padding-top: 16px;
      padding-bottom: 16px;

      &.id-cell {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
      }

      &.type-cell {
        .pill {
          width: max-content;
        }
      }

      &.name-cell {
        .name {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
            border-radius: $qm-border-radius-s;
          }
        }
      }
    }
  }
}
