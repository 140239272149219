@import '~styles/variables/colors';

.status-pill {
  text-transform: unset;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 2em;

  &.inline {
    display: inline-flex;
    align-items: baseline;
  }

  svg {
    height: 1em;
    width: 1em;
    stroke-width: 3;
    margin-right: 3px;
    align-self: center;
  }

  &.success {
    background-color: $qm-color-success;
    color: #044317;
  }

  &.error {
    background-color: #ffd7d9;
    color: #750e13;
  }

  &.warning {
    background-color: #f1c21b;
    color: #3d3927;
  }

  &.notify {
    background-color: #e0e0e0;
    color: #510224;
  }

  &.info {
    background-color: #d0e2ff;
    color: #0043ce;
  }

  &.alert {
    background-color: #ff832b;
    color: #fff;
  }
}
