.import-measure-table-page {
  h2 {
    margin-bottom: 0.4em;
  }

  p {
    font-family: MaisonNeue-Medium;
    max-width: 408px;
  }

  a {
    text-decoration: none;
  }

  .document {
    display: inline-block;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 64px;
  }
}
