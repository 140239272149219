@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';
.sources-detail-page {
  padding-bottom: 80px;
  .row {
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }

  .sources-header {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;

    .buttons-container {
      margin-left: auto;
      display: flex;
      flex-direction: row;

      > * {
        margin-left: $qm-padding;
      }
    }

    .details {
      .name {
        font-family: 'MaisonNeue-DemiBold';
        font-size: 42px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 50px;
        color: $qm-color-text;
        margin-bottom: 8px;
      }

      .info {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        > * {
          margin-right: $qm-margin-m;
        }

        > span {
          color: $qm-color-text-tertiary;
          font-family: MaisonNeue-Medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }

  .sources-media {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 400px;

    .map-container {
      flex: 100%;
      border-radius: $qm-border-radius-m;
      position: relative;
      overflow: hidden;

      .qm-google-map {
        width: 100%;
        height: 100%;
      }

      .location-info {
        display: block;
        width: fit-content;
        max-width: 246px;
        background-color: $qm-white;
        padding: $qm-margin-m;
        border-radius: $qm-border-radius-m;

        position: absolute;
        left: $qm-margin-m;
        bottom: $qm-margin-m;

        > p {
          color: $qm-color-text-secondary;
          font-family: MaisonNeue-Book;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;

          &.title {
            color: $qm-color-text;
            font-family: MaisonNeue-Medium;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }

    .gallery-container {
      flex: 100%;
      margin-left: $qm-margin-m;
    }
  }

  .user-list {
    .block-body {
      display: flex;
      flex-wrap: wrap;
      row-gap: $qm-padding-ms;

      > * {
        margin-right: $qm-padding;
      }
    }
  }

  .info-block {
    .block-title {
      font-family: 'MaisonNeue-Book';
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      color: $qm-color-text;
      margin-bottom: 12px;
    }

    .block-body {
      .empty {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
        max-width: 400px;
      }
    }
  }
  .associated-table-title {
    display: flex;
    align-items: center;
    padding: 40px 40px 0 40px;

    h2 {
      font-family: 'MaisonNeue-Book';
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      margin-right: 24px;
    }
  }

  .target-date-cell {
    display: table-cell !important;
  }

  .info-message {
    margin-top: 48px;
    text-align: center;
  }
}
