@import 'src/styles/variables/sizes';

.qm-modal-portal {
  .qm-modal.qm-confirmation-dialog {
    max-width: 65ch;

    .body {
      padding-left: $qm-padding-m;
      padding-right: $qm-padding-m;
      overflow: initial;
    }

    .footer {
      display: flex;
      gap: $qm-margin-m;
    }
  }
}
