@import '~styles/variables/sizes';

.companies-edit-page {
  h1 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .page-description {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    max-width: 450px;
    margin-bottom: $qm-margin-l;
  }
}
