@import 'src/styles/variables/_sizes.scss';
@import 'src/styles/variables/_colors.scss';

.icon-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  &:disabled {
    .icon {
      cursor: not-allowed;
      background-color: rgba($qm-color-primary-darker, 0.2);
      color: rgba($qm-color-primary-darker, 0.5);
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 18px;
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: $qm-button-height;
    height: $qm-button-height;
    background-color: $qm-color-primary-darker;

    &:first-child {
      border-top-left-radius: $qm-border-radius-s;
      border-bottom-left-radius: $qm-border-radius-s;
    }

    &:last-child {
      border-top-right-radius: $qm-border-radius-s;
      border-bottom-right-radius: $qm-border-radius-s;
    }
  }
}
