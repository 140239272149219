@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-defects-modal {
  max-width: 600px;
  width: 50vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .qm-input {
      width: 330px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .defects-modal-actions {
      display: flex;

      > * + * {
        margin-left: $qm-margin-m;
      }
    }
    .invalid-feedback {
      margin-top: $qm-margin-xs;
      margin-bottom: 0;
    }
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .id-cell {
      width: 15ch;
    }

    .name-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .table-row {
      &:hover {
        background-color: $qm-color-row-hover;
      }

      &.row-selected {
        background-color: $qm-color-row-selected;

        .name-cell {
          $checkSize: 18px;
          $checkMargin: 4px;
          padding-left: $qm-padding-m - $checkSize - $checkMargin;

          .check-selected {
            color: #42be65;
            margin-right: $checkMargin;
            width: $checkSize;
            height: $checkSize;
          }
        }
      }
    }
  }
}
