@import '~styles/variables/colors';
@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.users-form {
  .form-row {
    margin-bottom: $qm-padding;
  }

  .qm-input-container {
    width: 440px;
  }

  .roles-select-container,
  .status-input-container,
  .tags-select-container,
  .country-select-container {
    max-width: 440px;
  }

  .submit-row {
    width: auto;
  }

  .field-description {
    margin-top: $qm-margin-xs;
    max-width: 440px;
  }

  .medium-button {
    margin-top: 16px;
  }

  .error-bag {
    margin-top: $qm-margin-xs;
  }

  .multi-value-field {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
  }

  .photo-controls {
    display: flex;
    align-items: center;

    .qm-button {
      margin-left: 32px;
      margin-top: 0px;
    }
  }

  .photo-input-container {
    padding-bottom: 6px;
  }

  .remove-photo-button {
    position: absolute;
    margin-left: 70px;
    margin-bottom: 60px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url('../../../../assets/images/remove.svg') no-repeat;

    &.hidden {
      visibility: hidden;
    }
  }

  // TODO remove this after we have proper icon buttons
  .trash-button {
    margin-left: $qm-margin-m;
    color: #a2191f;
    background: transparent;
    border: none;
    font-family: 'Maison Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: $qm-button-height;
    margin-top: 26px;

    img {
      margin-right: 8px;
    }
  }
}
