@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-language-select {
  border-bottom: 1px solid $qm-gray-lighter;
  padding: 0;
  display: inline-block;

  .tabs-item {
    margin: 0;
    padding: $qm-margin-xs $qm-padding-ms;
  }
}
