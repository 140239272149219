@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.inspectors-modal {
  max-width: 900px;
  width: 80vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .search-input-container {
      width: 330px;
    }

    > * + * {
      margin-left: $qm-margin-m;
    }

    .inspection-date-container {
      display: flex;
      align-items: center;
      gap: $qm-margin-s;
      margin-left: auto;
    }
  }

  .footer {
    display: flex;

    .pagination-container {
      margin-left: auto;
    }
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-cell {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .name-cell {
      display: flex;
      flex-direction: row;
      align-items: center;

      .inspector-starred-icon {
        margin-left: 0.5em;
        color: $qm-color-warning;
        width: 1.5em;
        height: 1.5em;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .table-row {
      .check-selected {
        display: none;
      }

      &.row-selected {
        background-color: #f5f5f5;

        .name-cell {
          $checkSize: 18px;
          $checkMargin: 4px;
          padding-left: $qm-padding-m - $checkSize - $checkMargin;

          .check-selected {
            display: block;
            color: #42be65;
            margin-right: $checkMargin;
            width: $checkSize;
            height: $checkSize;
          }
        }
      }
    }
  }
}
