@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-resource-select-button {
  $thumbnail-size: 44px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $qm-padding-s;
  padding: $qm-padding-s;
  border: 1px solid transparent;
  border-radius: $qm-border-radius-m;
  background-color: $qm-color-input;
  height: 68px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: border 0.1s;

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid $qm-color-primary;
  }

  .placeholder {
    color: $qm-color-text-secondary;
    // color: hsl(0, 0%, 50%);
    font-family: MaisonNeue-Book;
    font-size: 16px;
    font-weight: 300;
    height: 22px;
    line-height: 22px;
  }

  .thumbnail {
    flex: 0 0 $thumbnail-size;
    border-radius: $qm-border-radius-s;
    width: $thumbnail-size;
    height: $thumbnail-size;
    object-fit: cover;
  }

  .text {
    flex: 1;
    min-width: 0;
    color: $qm-color-text-secondary;

    display: flex;
    flex-direction: column-reverse;

    .title {
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    .subtitle {
      font-family: MaisonNeue-Book;
      font-size: 14px;
      font-weight: 300;
      height: 18px;
      line-height: 18px;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .text,
    .placeholder {
      color: $qm-color-text-tertiary;
    }

    .qm-toggle {
      cursor: not-allowed;
      color: $qm-color-text-tertiary;
    }

    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }

  .clear-icon {
    color: $qm-color-text-tertiary;
    transition: color 0.1s;

    &:hover {
      color: $qm-color-text-secondary;
    }
  }

  &.is-invalid {
    background: url('../../../assets/images/alert-circle.svg');
    background-size: 20px;
    background-position: right 40px bottom 50%;
    background-repeat: no-repeat;

    &,
    &:hover,
    &:focus {
      border-color: $qm-color-error;
    }
  }
}
