@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.select-input {
  min-height: 44px;

  .select-input-create-label {
    color: $qm-color-text-link;
    padding-right: 0px;
    padding-left: 0px;
    cursor: pointer;

    .select-input-create-label-value {
      font-weight: bold;
    }
  }

  &.is-invalid {
    .qm-select__control {
      background: url('../../../assets/images/alert-circle.svg');
      background-size: 20px;
      background-position: right 40px bottom 50%;
      background-repeat: no-repeat;
      border-color: $qm-color-error;
    }
  }

  .dropdown-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    background-color: rgba($qm-color-primary, 0.1);

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
