@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.settings-questions-list {
  grid-template-columns: [edit] fit-content(1em) [id] fit-content(5em) [question] auto [type] auto [sample-size] auto [toggle] 24px [end];
  grid-auto-rows: auto;
  display: grid;

  .table-row {
    display: contents;
  }

  .qm-question-detail,
  .qm-question-form {
    &:nth-child(2) {
      .table-cell:not(.body-cell) {
        padding-top: $qm-margin-m;
      }
    }

    &:last-child {
      .table-row:last-child {
        .table-cell {
          padding-bottom: 0;
        }
      }
    }

    .table-cell {
      padding-right: $qm-margin-m;
      padding-bottom: $qm-margin-m;

      &.id-cell {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
        white-space: nowrap;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .question-form-actions {
    display: contents;

    .table-cell.body-cell {
      grid-column-start: question;
      grid-column-end: end;
      margin-bottom: 24px;
    }
    .question-actions-container {
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: $qm-padding;
      }
    }
  }

  .table-cell {
    &.align-right {
      text-align: right;
      justify-content: flex-end;
    }

    &.align-center {
      text-align: center;
      justify-content: space-around;
    }
  }

  .table-header {
    .table-cell {
      font-family: MaisonNeue-Medium;
      border-bottom: 1px solid $qm-gray-lighter;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: $qm-color-text-tertiary;
      padding-bottom: $qm-margin-xs;
      padding-right: $qm-margin-m;

      &.edit-cell {
        vertical-align: center;
      }

      &:last-child {
        padding-right: 0;
      }

      .header-button {
        cursor: pointer;
        display: flex;
        flex-direction: row;

        .sort-icon {
          display: inline-block;
          margin-left: 8px;
          color: $qm-gray-lighter;

          &.active {
            color: $qm-color-selected;
          }
        }
      }
    }
  }
}
