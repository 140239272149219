@import '~styles/variables/colors';

.users-table {
  .table-cell {
    &.id-cell {
      font-family: 'MaisonNeue-Book';
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      color: $qm-color-text-secondary;
    }

    &.email-cell {
      word-wrap: anywhere;
    }

    &.phone-cell {
      word-wrap: anywhere;
    }

    &.pill-cell {
      .pill {
        margin-right: 10px;
      }

      .pill:last-child {
        margin-right: 0px;
      }
    }

    .roles-cell-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .pill {
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .user-status-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
