@import 'src/styles/variables/_colors.scss';

.progressBar {
  background-color: $qm-gray-lighter;
  min-width: 100px;
  height: 5px;
  border-radius: 2.5px;
  position: relative;

  .selected {
    background-image: url('../../assets/images/progress-pattern.png');
    background-repeat: repeat-x;
    width: 10%;
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    border-radius: 2.5px;
  }
}
