@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.inspection-third-party-form {
  $input-width: 440px;
  $input-width-m: 280px;
  $input-width-s: 180px;

  .form-row {
    margin-bottom: $qm-padding;
    width: $input-width;
  }

  .error-bag {
    margin-top: $qm-margin-xs;
    max-width: $input-width;
  }

  .field-description {
    margin-top: $qm-margin-xs;
    max-width: $input-width;
  }

  .date-input {
    input {
      width: $input-width-s;
    }
  }

  .window-input {
    width: initial;
  }

  .type-select {
    width: $input-width-m;
  }

  .inspection-report-container {
    .qm-file {
      margin-top: $qm-margin-s;
      margin-bottom: $qm-margin-s;
      width: fit-content;
    }
  }

  .list-controls {
    display: flex;
    flex-direction: row;
    > * {
      margin-right: $qm-padding;
    }
  }

  .submit-button {
    margin-top: $qm-margin-l;
  }

  .asset-select,
  .source-select {
    width: $input-width;
  }

  .inspector-select {
    .qm-resource-select-button-container {
      width: $input-width-m;
    }
  }

  .form-group {
    display: flex;
    gap: $qm-padding-ms;
    align-items: center;
  }
}
