@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  .login-form-container {
    margin: auto;
    position: relative;

    .qm-input-container {
      .left-insert {
        left: 0;
      }

      // mail svg has invisible box around it, must custom align
      &:first-child {
        .left-insert {
          margin-top: 9.5px;
          margin-left: 9px;
        }
      }
    }
  }
}
