@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-question-groups-modal {
  max-width: 900px;
  width: 80vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .qm-input {
      width: 330px;
    }
  }

  .pagination-container {
    margin-left: auto;
    width: max-content;
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-row {
      &:hover {
        background-color: $qm-color-row-hover;
      }

      &.row-selected {
        background-color: $qm-color-row-selected;

        .name-cell {
          $checkSize: 18px;
          $checkMargin: 4px;
          padding-left: $qm-padding-m - $checkSize - $checkMargin;
        }
      }
    }
  }
}
