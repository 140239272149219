@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.qm-question-form {
  display: contents;

  .error-bag {
    margin-top: 8px;
  }

  &.is-open {
    .edit-cell {
      grid-row: span 2;
    }
  }

  textarea {
    resize: vertical;
  }

  .table-cell {
    padding: 0;
    padding-right: $qm-margin-m;
    padding-bottom: $qm-margin-m;

    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &.body-cell {
      grid-column-start: question;
      grid-column-end: end;
      margin-bottom: $qm-margin-m;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .question-aql {
    width: 60px;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    gap: $qm-margin-m;
  }

  .field-description {
    margin-top: $qm-margin-xs;
    max-width: 440px;
  }

  .tools-needed-container,
  .asset-reference-documents-container {
    & > * + * {
      margin-top: $qm-margin-s;
    }
  }

  .defect-list-container {
    font-family: MaisonNeue-Book;
    color: $qm-color-text;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    .form-label {
      margin-bottom: $qm-margin-s;
    }

    ul.defect-list {
      > * + * {
        margin-top: 16px;
      }
    }

    .defect {
      display: grid;
      column-gap: $qm-padding-ms;
      grid-template-columns: min-content 220px 200px min-content;
      grid-template-rows: auto auto;
      align-items: center;

      &::before {
        content: '•';
        margin-left: 8px;
        grid-column: 1;
        grid-row: 1;
      }

      .error-bag {
        grid-column: 3;
        grid-row: 2;
        .invalid-feedback {
          margin-bottom: 0;
        }
      }
    }

    .system-defect-weight {
      margin-left: 18px;
    }

    .qm-button {
      margin-top: $qm-margin-m;
    }
  }

  .reference-documents-container {
    .form-label {
      margin-bottom: $qm-margin-s;
    }

    .document-list {
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: $qm-padding;
      }

      .document {
        .form-group {
          display: flex;
          flex-direction: row;
          align-items: center;

          .qm-input-container {
            margin-left: $qm-margin-xs;
            width: 280px;
          }

          .delete-button {
            margin-left: $qm-margin-m;
          }
        }
      }
    }
    .document-actions {
      margin-top: $qm-padding;
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: $qm-margin-s;
      }
    }
  }

  .question-weight-container,
  .answer-type,
  .answer-choice,
  .print-on-report,
  .tools-needed-container,
  .asset-reference-documents-container,
  .dependency-action,
  .dependency-criteria,
  .modifier-action,
  .modifier-rule,
  .expected-measure-container,
  .narrow-question-type-container {
    width: 280px;
  }

  .keywords-container {
    width: 345px;
  }

  .custom-measure-select {
    width: 180px;
  }
  .upper-tolerance-input,
  .lower-tolerance-input {
    width: 72px;
  }
  .upper-tolerance-weight,
  .lower-tolerance-weight,
  .expected-measure-uom-container {
    width: 200px;
  }
  .modifier-value,
  .dependency-question-container {
    width: 150px;
  }
  .barcode-uom-select {
    width: 120px;
  }

  .flex {
    display: flex;
    flex-direction: row;

    > * {
      display: flex;
      flex-direction: column;
    }

    > * + * {
      margin-left: $qm-margin-s;
    }
  }

  .aql-level-select-container {
    display: flex;
    flex-direction: row;
    gap: $qm-margin-s;
    margin-top: $qm-margin-m;

    .question-aql {
      &.aql-major {
        width: 140px;
      }

      &.aql-minor {
        width: 140px;
      }

      &.aql-functional {
        width: 140px;
      }
    }
    .critical-defect-container {
      display: flex;
      gap: $qm-margin-xs;

      .critical-input {
        width: 80px;
      }
    }
  }

  .aql-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: $qm-margin-s;
    justify-items: stretch;
    flex-wrap: wrap;

    .question-aql {
      &.aql-select {
        width: 140px;
      }
    }

    > * {
      flex: 0;
    }

    .aql-custom {
      > div {
        display: flex;
        flex-direction: row;
        gap: $qm-margin-s;
      }
      .error-item {
        text-align: left;
      }

      .qm-input {
        width: 80px;
      }
    }
  }

  .narrow-question-sample-size-container {
    .aql-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .error-bag {
        .invalid-feedback:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .question-summary {
    .textarea-info {
      display: none;
    }
  }

  .input-info {
    display: none;
  }

  .question-editing {
    .question-form > * + * {
      margin-top: $qm-margin-m;
    }
  }
}
