@import 'src/styles/variables/_sizes.scss';

.qm-breadcrumbs {
  margin-bottom: $qm-margin-xs;

  span {
    font-size: 14px;
  }

  .divider {
    margin: 8px;
  }

  span:last-of-type {
    display: none;
  }
}
