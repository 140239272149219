@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.settings-questions-list-page {
  .settings-questions-list {
    margin-top: $qm-margin-m;

    > * {
      border: 1px solid $qm-gray-lighter;
    }
  }

  .question-form-actions {
    .error-bag {
      margin-top: 8px;
    }
  }
}

.settings-question-list-page--add-modal {
  width: 80vw;
  height: 90vh;
  max-width: 800px;
  max-height: 1200px;

  .body {
    display: grid;
    grid-template-columns: [question] auto [type] auto [sample-size] auto [toggle] 24px [end];
    grid-auto-rows: auto;

    .add-question-errorbag,
    .question-name-label {
      grid-column-start: question;
      grid-column-end: end;
    }

    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;

    .table-row {
      display: contents;
    }

    .edit-cell,
    .id-cell {
      display: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;

    > * + * {
      margin-left: $qm-padding;
    }
  }
}
