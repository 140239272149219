@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-toggle {
  flex: 0 0 20px;
  margin-left: auto;
  cursor: pointer;
  background-color: rgba($qm-color-primary, 0.1);
  color: $qm-color-primary;
  border: none;
  border-radius: $qm-border-radius-s;
  padding: 0;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  transform: rotate(0deg);

  &.disabled {
    color: $qm-color-text-tertiary;
    cursor: not-allowed;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.down {
    transform: rotate(180deg);
  }
  &.right {
    transform: rotate(90deg);
  }
  &.left {
    transform: rotate(270deg);
  }
}
