.settings-asset-statuses-table {
  width: 100%;

  .table-header .table-cell {
    &.id-cell {
      width: 50px;
      height: 16px;
      padding-bottom: 11px;
    }
    .name-cell {
      height: 16px;
      padding-bottom: 11px;
    }
  }

  .qm-input-container .qm-input {
    width: 180px;
  }
}
