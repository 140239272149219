@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.settings-asset-statuses-list-page {
  p {
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    max-width: 420px;
  }

  .asset-statuses-filter-container {
    display: flex;
    align-items: center;
    gap: 32px;
    margin: 42px 0 21px 0;

    .input-info {
      margin-bottom: 0;
    }

    .qm-input-container .qm-input {
      width: 200px;
    }
  }

  .qm-table .table-row .table-cell {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 0;
    vertical-align: baseline;
    text-align: left;
    height: 50px;
  }

  .qm-table .table-row:hover {
    background-color: white;
  }

  .container-m {
    padding: 24px 0;
  }
}
