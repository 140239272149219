@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.companies-detail-page {
  .company-header {
    display: flex;
    gap: 24px;
    padding-bottom: 0;

    .buttons-container {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 40px;
    }

    .details {
      display: flex;
      flex-direction: column;

      .name {
        font-family: 'MaisonNeue-DemiBold';
        font-weight: 600;
        font-size: 42px;
        line-height: 50px;
        color: $qm-color-text;

        margin-bottom: 12px;
        margin-top: 16px;
      }

      .info {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;

        font-family: 'MaisonNeue-Medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        color: $qm-color-bg;

        span {
          text-transform: capitalize;
          display: inline-block;
        }
      }
      .description {
        max-width: 600px;
      }
    }
  }

  .contact-information {
    display: flex;
    flex-direction: row;
    gap: 80px;
    padding-bottom: 0;
    margin-top: 16px;

    font-family: 'MaisonNeue-Book';
    font-weight: 300;
    color: $qm-color-text;

    .group {
      .info {
        line-height: 22px;
        font-size: 16px;
        margin-bottom: 4px;
        a {
          text-decoration: none;
          display: flex;
          gap: 12px;
          align-items: center;
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }

      .title {
        font-size: 14px;
        line-height: 18px;
        color: $qm-color-bg;
        margin-bottom: 8px;
      }

      .bold {
        font-family: 'MaisonNeue-Bold';
        font-weight: bold;
      }
    }
  }

  .info-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .info-card {
      flex: 1;

      .title {
        font-family: 'MaisonNeue-Book';
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        color: $qm-color-text;
        margin-bottom: 12px;
      }
    }
  }
  .about-container {
    max-width: 494px;
  }
  .client-admin-container {
    .client-admin-list {
      display: flex;
      align-items: center;
      column-gap: 56px;
      row-gap: 24px;
      flex-wrap: wrap;
    }
  }
  .row {
    margin: 32px 0;
  }
  h4 {
    font-family: 'MaisonNeue-Book';
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
