@import "src/styles/variables/_colors.scss";
@import "src/styles/variables/_sizes.scss";

.inspections-detail-page {
  font-family: MaisonNeue-Book;
  .bold {
    font-family: MaisonNeue-Bold;
  }

  .qm-input-container .input-info {
    margin-bottom: 0;
  }

  .external-report-document {
    text-decoration: none;
  }

  .qm-third-party-pill {
    display: inline-flex;
    margin-left: 4px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .row {
    display: flex;
    flex-direction: row;

    margin-bottom: $qm-margin-l;

    &.inspections-header {
      justify-content: space-between;
    }

    .details {
      width: 50%;

      h2 {
        margin-bottom: $qm-margin-s;
      }
    }

    .info {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 24px;
      }
    }
  }

  .cards {
    display: flex;
    gap: 40px;
    margin-bottom: 24px;

    .card-column {
      flex: 1;
      max-width: 580px;
    }
  }
  h5 {
    color: rgb(22, 22, 22);
    font-family: MaisonNeue-Book;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 8px;
  }

  h5.header-with-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $qm-padding-s;
  }

  .actions {
    display: flex;
    gap: $qm-padding-s;
  }

  .plan-needs-review-button {
    padding: 0;
    background-color: #ecf2ff;
    width: 20px;
    height: 20px;

    svg {
      color: $qm-color-primary;
      width: 14px;
      height: 14px;
    }
  }

  .source-info {
    display: flex;
    margin-bottom: 16px;

    div {
      margin-left: 4px;
    }
  }

  .work-object-info {
    margin-bottom: 24px;
  }

  .inspection-info {
    display: flex;
    gap: 80px;
    flex-wrap: wrap;

    & > div > p {
      margin-bottom: 6px;
    }
  }

  .dates-container {
    display: flex;
    flex-direction: column;
    gap: $qm-margin-m;
  }

  .pill-container {
    display: flex;
    align-items: baseline;

    svg {
      stroke-width: 3;
      margin-right: 3px;
    }

    .status-pill {
      white-space: nowrap;
    }
  }

  .defects-container {
    display: flex;
    flex-direction: column;
    margin-top: $qm-padding-ms;
    gap: $qm-margin-xs;
  }

  .view-results-button {
    margin-top: $qm-margin-m;
  }

  .inspector-group,
  .date-group {
    display: flex;
    align-items: center;
    gap: $qm-margin-l;
  }

  .decision-history {
    .previous-decision {
      &:not(:first-of-type) {
        margin-top: $qm-padding;
      }

      .decision-notes {
        font-size: 14px;
        font-family: MaisonNeue-Medium;
        color: $qm-color-text-secondary;
        white-space: pre-wrap;
        margin-top: $qm-margin-s;
        max-width: 380px;
      }

      .decision-name {
        margin-top: $qm-margin-s;
        font-family: MaisonNeue-Book;
        color: $qm-color-text-tertiary;
      }
    }
  }

  .inspection-documents-container {
    display: flex;
    margin-top: $qm-margin-l;
    gap: $qm-margin-l;
    flex-wrap: wrap;

    h5 {
      margin-bottom: $qm-margin-m;
    }

    .associated-documents-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: $qm-margin-s;
    }

    > * {
      flex: 1;
    }

    .external-report-document .qm-file {
      width: fit-content;
    }
  }
}
