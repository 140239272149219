$feature-flags: (
  grid-columns-16: true,
);
@import './styles/reset.css';

@import './assets/font/stylesheet.css';

* {
  outline: none !important;
}
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
