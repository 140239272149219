@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.qm-table-container {
  overflow-x: clip;

  &.overflow-x {
    max-height: calc(100vh - 124px);
    flex: 1;
    overflow-x: auto;
    overflow-y: visible;
  }
}

.qm-table {
  display: table;
  width: 100%;
  border-collapse: separate;
  position: relative;
  z-index: 0;

  .empty-state {
    text-align: center;
    padding: 1em;
    display: table-caption;
    caption-side: bottom;

    p {
      max-width: 400px;
      margin: 0 auto;
      color: $qm-color-text-secondary;
    }
  }

  .table-row {
    display: table-row;

    &.table-row-clickable {
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.table-header {
      .table-cell {
        z-index: 1;
        font-family: 'MaisonNeue-Medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $qm-color-text-tertiary;
        padding-bottom: 16px;
        padding-top: 16px;
        position: sticky;
        top: 0;
        background-color: #fff;
        border-bottom: 1px solid $qm-gray-lighter;
      }

      .header-button {
        cursor: pointer;
        display: inline-flex;
        flex-direction: row;

        .sort-icon {
          display: inline-block;
          margin-left: 8px;
          color: $qm-gray-lighter;
          &.active {
            color: $qm-color-selected;
          }
        }
      }

      .header-button,
      .header-label {
        &:not(:first-child)::before {
          content: '/';
          padding-left: 4px;
          padding-right: 4px;
          display: inline-block;
        }
      }
    }
  }

  .table-cell {
    display: table-cell;
    padding: $qm-padding;
    vertical-align: middle;
    color: $qm-color-text;

    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &:first-child {
      padding-left: $qm-padding-m;
    }

    &:last-child {
      padding-right: $qm-padding-m;
    }

    &.align-left {
      text-align: left;
      .header-button {
        justify-content: flex-start;
      }
    }

    &.align-right {
      text-align: right;
      .header-button {
        justify-content: flex-end;
      }
    }

    &.align-center {
      text-align: center;
    }

    &.align-justify {
      text-align: justify;
    }

    &.id-cell {
      font-family: 'MaisonNeue-Book';
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      color: $qm-color-text-secondary;
      white-space: nowrap;
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }
}

.qm-table-controls {
  border-top: 1px solid $qm-gray-lighter;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .page-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 22px;

    .page-size-select {
      margin-left: 8px;
      width: 146px;
    }
  }

  .pagination {
    height: $qm-input-height;
    display: flex;
    align-items: center;
  }
}
