@import '~styles/variables/colors';

.avatar {
  img {
    border-radius: 50%;
  }

  &.avatar-s {
    img,
    .placeholder {
      width: 42px;
      height: 42px;
    }
  }

  &.avatar-m {
    img,
    .placeholder {
      width: 44px;
      height: 44px;
    }
  }

  &.avatar-l {
    img,
    .placeholder {
      width: 56px;
      height: 56px;
      font-size: 24px;
    }
  }

  &.avatar-xl {
    img,
    .placeholder {
      width: 90px;
      height: 90px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  .placeholder {
    background-color: #6b9aff;
    font-family: 'MaisonNeue-ExtraBold';
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    color: $qm-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
