@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';
.qm-settings-question-group {
  .question-group-name {
    display: flex;
    align-items: center;
  }

  .question-group-form {
    font-size: 16px;
    font-family: MaisonNeue-Book;
    font-weight: 300;
    width: 440px;

    .form-row {
      margin-bottom: $qm-padding;
    }

    .field-description {
      margin-top: $qm-margin-xs;
    }

    .question-group-name-container {
      input {
        width: 280px;
      }
    }

    .question-group-order-container {
      input {
        width: 100px;
      }
    }
  }

  .empty {
    grid-column-start: question;
    grid-column-end: end;

    font-family: 'MaisonNeue-Book';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: $qm-color-text-secondary;
    max-width: 400px;
  }

  .question-group-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    column-gap: $qm-padding-ms;
    row-gap: $qm-padding-s;

    .question-group-order,
    .question-group-tags,
    .question-group-asset-types,
    .question-group-inspection-types,
    .question-group-custom-attributes,
    .question-group-company-attributes {
      display: flex;
      flex-wrap: wrap;
      row-gap: $qm-margin-xs;
      flex-direction: row;
      align-items: center;

      span {
        color: $qm-color-text;
        font-family: MaisonNeue-Medium;
        font-size: 12px;
        font-weight: 500;
      }
      > * + * {
        margin-left: $qm-margin-xs;
      }
    }
  }

  .question-group-add-question {
    margin-left: auto;
  }

  .questions-table {
    grid-template-columns: [id] fit-content(5em) [question] auto [type] auto [sample-size] auto [toggle] 24px [end];
    grid-auto-rows: auto;
    display: grid;

    .table-row {
      display: contents;

      &.question-summary {
        .id-cell {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    p {
      max-width: 40ch;
    }

    .table-cell {
      padding-right: $qm-margin-m;

      &:last-child {
        padding-right: 0;
      }

      &.align-right {
        text-align: right;
        justify-content: flex-end;
      }

      &.align-center {
        text-align: center;
        justify-content: space-around;
      }

      &.edit-cell {
        display: none;

        svg {
          vertical-align: middle;
        }
      }
    }

    .table-header {
      .table-cell {
        border-bottom: 1px solid $qm-gray-lighter;
        font-family: 'MaisonNeue-Medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $qm-color-text-tertiary;
        padding-bottom: $qm-margin-xs;
      }
    }
  }

  &.editable {
    .questions-table {
      grid-template-columns: [edit] fit-content(1em) [id] fit-content(5em) [question] auto [type] auto [sample-size] auto [toggle] 24px [end];

      .edit-cell {
        display: block;
      }
    }
  }

  .question-form-actions {
    display: contents;

    .table-cell.body-cell {
      grid-column-start: edit;
      grid-column-end: end;
    }
    .question-actions-container {
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: $qm-padding;
      }
    }
  }

  .question-actions {
    display: flex;
    flex-direction: column;
    gap: $qm-margin-xs;

    // > * {
    //   gap: $qm-margin-xs;
    // }

    .action-group {
      position: relative;
      .extra {
        display: none;
        position: absolute;
      }
      &.down {
        .extra {
          bottom: -100%;
        }
      }

      &.up {
        .extra {
          top: -100%;
        }
      }

      &:hover {
        .extra {
          display: block;
        }
      }
    }
  }

  .qm-question-detail {
    &:last-child {
      .table-row:last-child {
        .table-cell {
          padding-bottom: 0;
        }
      }
    }

    &:nth-child(2) {
      .table-cell:not(.body-cell) {
        padding-top: $qm-margin-m;
      }
    }
  }

  .dependency-container {
    margin-top: $qm-padding;
    margin-bottom: $qm-margin-m;

    .flex {
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: $qm-margin-s;
      }
    }

    .dependency-action {
      width: 140px;
    }

    .dependency-criteria {
      width: 280px;
    }

    .dependency-question-container {
      width: 150px;
    }
  }

  .quick-edit-question-button {
    margin-top: $qm-margin-m;
  }

  .edit-question-group-button {
    display: block;
    background: transparent;
    border: none;
    color: $qm-color-selected;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-left: $qm-margin-xs;

    svg {
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }

  .accordion-header .header-actions {
    justify-content: flex-end;
  }
}
