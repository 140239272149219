@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.inspection-plans-modal {
  max-width: 900px;
  width: 80vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .qm-input {
      width: 330px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        white-space: nowrap;
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-cell {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .aql-cell {
      * {
        white-space: nowrap;
      }
    }
  }
}
