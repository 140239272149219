@import '../../styles/variables/colors';
@import '../../styles/variables/sizes';

.profileMenu {
  .profile-menu-dropdown-container {
    font-family: 'Maison Neue';
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 8px 16px;

    &:hover {
      background: #e5e5e5;
    }
  }
  #profile-menu-dropdown {
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      font-family: 'Maison Neue';
    }

    .bx--list-box__menu-icon .bx--list-box__menu-icon > svg {
      height: 100%;
      fill: $qm-color-primary;
    }

    .bx--list-box__menu-item {
      font-family: 'Maison Neue';
    }

    .bx--list-box__menu-icon {
      background-color: #e1e8fa;
      height: 20px;
      width: 20px;
      border-radius: $qm-border-radius-s;
      display: flex;
      justify-content: center;
      margin-top: 6px;
    }

    button {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
