@import 'styles/variables/_sizes';

.inspection-plans-add-page {
  h2 {
    margin-bottom: $qm-padding-l;
  }

  .inspection-plan-tabs {
    margin-bottom: 32px;
  }
  .tabs-container.tabs-separator {
    padding-left: 40px;
    margin-left: -40px;
    margin-right: -40px;
  }

  .tabs-container {
    .active {
      font-weight: bold;
    }
  }
}
