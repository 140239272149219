@import '~styles/variables/colors';

.timeline-new-item {
  display: flex;
  align-items: center;
  height: 44px;

  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: $qm-color-text-link;
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}
