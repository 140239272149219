@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.defect-item {
  display: flex;
  gap: $qm-padding;
  align-items: center;

  .no-translation {
    color: $qm-color-text-tertiary;
  }
}
