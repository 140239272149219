@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.floating-card {
  padding: 24px;

  background-color: white;
  border-radius: 10px;
  border: 1px solid $qm-gray-lighter;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  flex: 1;

  .card-icon {
    margin-bottom: $qm-margin-s;
    width: $qm-button-height;
    height: $qm-button-height;
    border-radius: 50%;
    background-color: rgba($qm-color-selected, 0.1);
    color: $qm-color-selected;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .card-value {
    font-family: MaisonNeue-DemiBold;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  .card-description {
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
    transition: transform 0.2s, box-shadow 0.2s;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    will-change: transform;
    transform: scale(1.00001) translateZ(0);

    &:hover {
      transform: scale(1.03) translateZ(0);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    }
  }
}
