@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .checkbox-control {
    background-color: $qm-color-input;
    display: inline-grid;
    place-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    width: 19px;
    height: 19px;
    cursor: pointer;
    color: $qm-white;

    svg {
      opacity: 0;
      width: 14px;
    }
  }

  &.outline {
    .checkbox-control {
      background-color: transparent;
      border: 1px solid $qm-gray-lighter;
    }
  }

  .checkbox-label {
    margin-left: 8px;
  }

  .checkbox-input {
    display: grid;
    grid-template-areas: 'checkbox';

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 20px;
      height: 20px;
      margin: 0;
      cursor: pointer;

      &:focus:not(:disabled) + .checkbox-control,
      &:hover:not(:disabled) + .checkbox-control {
        box-shadow: 0px 2px 6px 0px rgba($qm-color-primary, 0.11);
        border-color: $qm-color-primary;
      }

      &:disabled {
        cursor: not-allowed;

        & + .checkbox-control {
          cursor: not-allowed;
          color: $qm-color-text-tertiary;
          background-color: $qm-color-input;
        }
      }
    }
  }

  input:checked + .checkbox-control,
  &.indeterminate .checkbox-control {
    background-color: $qm-color-primary;
    border-color: $qm-color-primary;

    svg {
      opacity: 1;
    }
  }
}
