@import 'src/styles/variables/_colors';

.settings-asset-statuses-form {
  display: flex;
  gap: 32px;

  .input-info {
    margin-bottom: 0;
  }

  .original-status {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .status-value {
      height: 32px;
      padding-top: 14px;
      color: $qm-color-text-secondary;
    }
  }

  .form-buttons {
    display: flex;
    gap: 32px;
    padding-top: 32px;

    .qm-button {
      height: 32px;
    }
  }

  .cancel-button {
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .error-bag {
    .invalid-feedback {
      margin-bottom: 0;
    }
  }
}
