@import 'src/styles/variables/_colors';
.qm-taggable-entity {
  cursor: pointer;

  ellipse {
    fill: $qm-gray-medium;
    stroke-width: 1px;
    stroke: $qm-color-primary;
  }

  .icon {
    stroke: $qm-color-primary;
  }

  &.selected {
    cursor: move;

    ellipse {
      fill: $qm-color-primary;
    }

    .icon {
      stroke: $qm-white;
    }
  }
}
