@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.users-detail-page {
  .user-header {
    display: flex;
    gap: 24px;
    padding-bottom: 0;

    .buttons-container {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 24px;

      .resend-password-container {
        width: 198px;
        display: inline-block;

        .message {
          text-align: center;
          margin-top: 24px;
          font-size: 16px;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;

      .name {
        font-family: 'MaisonNeue-Medium';
        font-weight: 500;
        font-size: 28px;
        line-height: 22px;
        color: $qm-color-text;

        margin-bottom: 12px;
        margin-top: 16px;
      }

      .info {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;

        font-family: 'MaisonNeue-Medium';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        color: $qm-color-bg;

        span {
          text-transform: capitalize;
          display: inline-block;
        }
      }

      .tags-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }
  }

  .contact-information {
    display: flex;
    flex-direction: row;
    gap: 80px;
    padding-bottom: 0;

    font-family: 'MaisonNeue-Book';
    font-weight: 300;
    color: $qm-color-text;

    .group {
      &:first-child {
        margin-left: calc(90px + 24px);
      }

      .info {
        line-height: 22px;
        font-size: 16px;
        margin-bottom: 4px;
        a {
          text-decoration: none;
          display: flex;
          gap: 12px;
          align-items: center;
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }

      .title {
        font-size: 14px;
        line-height: 18px;
        color: $qm-color-bg;
        margin-bottom: 8px;
      }

      .bold {
        font-family: 'MaisonNeue-Bold';
        font-weight: bold;
      }
    }
  }

  .info-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .right-container {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 48px;
    }

    .info-card {
      flex: 1;

      .card-title {
        font-family: 'MaisonNeue-Book';
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        color: $qm-color-text;
        margin-bottom: 12px;
      }

      .card-body {
        display: flex;
        flex-wrap: wrap;
        column-gap: 48px;
        row-gap: 32px;
      }

      .source-name {
        font-family: 'MaisonNeue-Bold';
        color: $qm-color-text;
        font-size: 16px;
        font-weight: bold;
      }

      .source-location {
        font-family: 'MaisonNeue-Book';
        color: $qm-color-text-secondary;
        font-size: 16px;
        font-weight: 300;
      }

      .empty {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
        max-width: 400px;
      }

      a {
        color: initial;
        text-decoration: none;
      }
    }
  }
}
