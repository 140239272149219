@import '~styles/variables/colors';

.timeline-item {
  position: relative;
  padding: 0 0 0 64px;

  &:first-of-type {
    overflow: hidden;
  }

  &:last-of-type {
    padding-bottom: 0;

    &::after {
      top: auto;
      bottom: 10px;
    }

    .timeline-subitem:last-of-type {
      padding-bottom: 0px;

      &::after {
        top: 0;
        bottom: auto;
        height: 32px+15px;
      }
    }
  }

  .item-title {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    height: 22px;
    line-height: 22px;
    padding: 0;
    margin: 0 0 4px 0;
  }

  .item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    font-family: 'MaisonNeue-Medium';

    color: $qm-color-text-link;
    font-size: 16px;
    font-weight: 900;
    height: 40px;
    line-height: 22px;
    width: 40px;

    img {
      width: 24px;
      height: 24px;
    }

    .completed-item-icon,
    .failed-item-icon {
      border-radius: 50%;
      padding: 4px;
    }

    .completed-item-icon {
      background: $qm-color-success;
    }

    .failed-item-icon {
      background: $qm-color-error-light;
    }
  }

  &::before {
    width: 39px;
    height: 39px;
    border: 1px solid $qm-color-text-link;
    border-radius: 50%;
    background: #fff;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &::after {
    // width: 1px;
    // display: block;
    // background: $qm-color-text-link;
    // height: 100%;
    // content: '';
    // position: absolute;
    // top: 0;
    // left: 20px;
  }
}
