@import 'src/styles/variables/sizes';
@import 'src/styles/variables/colors';

.qm-company-select-dropdown-container {
  min-width: 160px;
  border: 1px solid $qm-gray-lighter;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: $qm-padding-s;

  cursor: pointer;

  .selected-company {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selected-company-title {
      color: $qm-color-text-tertiary;
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .selected-company-label {
      color: $qm-color-text;
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .company-toggle {
    margin-left: auto;
  }

  .company-logout-icon {
    margin-left: auto;
    font-size: 24px;
    width: 24px;
    flex-shrink: 0;
    color: rgba($qm-color-primary, 0.6);
  }
}
