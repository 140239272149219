@import "src/styles/variables/sizes";
@import "src/styles/variables/colors";

.qm-settings-question-form {
  .dependency-notice {
    width: 480px;
    border: 1px solid $qm-color-primary;
    border-radius: 6px;
    padding: 12px 12px 12px 16px;

    .dependency-notice-text {
      font-size: 0.9em;
      line-height: 1.2;
    }

    .dependency-notice-actions {
      margin: 0.5em 0;
    }

    .qg-list {
      margin-top: 0.5em;
      line-height: 1.1;
      li {
        &:not(:first-child) {
          margin-top: 0.25em;
        }
        font-weight: 600;
      }
    }
  }
  .qm-language-select {
    margin-top: $qm-padding-m;
    margin-bottom: $qm-padding-m;
  }
  .tabs-container {
    margin-bottom: $qm-margin-m;
  }
  .sample-size-cell,
  .toggle-cell,
  .id-cell,
  .edit-cell,
  .question-type-cell {
    display: none;
  }

  .qm-textarea-container {
    width: 480px;
  }
}
