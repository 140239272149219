$qm-color-primary: #6320ee;
$qm-color-primary-darker: #4f19bf;

$qm-color-bg: #a8a8a8;
$qm-color-error: #da1e28;
$qm-color-error-light: #ffd7d9;
$qm-color-warning: #ff832b;
$qm-color-success: #a7f0ba;
$qm-color-delete: #fa4d56;

$qm-white: #ffffff;
$qm-gray-light: #d6d6d6;
$qm-gray-lighter: #e0e0e0;
$qm-gray-medium: #f4f5fb;
$qm-color-blue-light: #ecf2fe;

$qm-color-input: #f4f4f4;

$qm-color-selected: #6320ee;

$qm-color-text: #161616;
$qm-color-text-secondary: #525252;
$qm-color-text-tertiary: #a8a8a8;
$qm-color-text-link: #6320ee;

$qm-color-text-success: #00825e;
$qm-color-text-warning: #9f7b22;

$qm-color-row-hover: #f5f5f5;
$qm-color-row-selected: $qm-color-row-hover;
