@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.qm-answer-detail {
  display: contents;

  &.is-hidden {
    .table-cell {
      opacity: 0.4;
    }
  }

  .attributes-container {
    font-size: 16px;
    line-height: 22px;
    font-family: MaisonNeue-Book;

    > * + * {
      margin-top: $qm-margin-xs;
    }

    .attribute {
      display: flex;
      flex-direction: row;

      .label {
        color: $qm-color-text-secondary;
        font-weight: 300;

        &::after {
          content: ': ';
          white-space: pre;
        }
      }

      .value {
        display: flex;
        flex-direction: column;
        color: $qm-color-text;
        font-family: MaisonNeue-Bold;
        font-weight: bold;
        white-space: pre-wrap;
      }
    }

    .defect-attribute {
      align-items: center;

      .value {
        display: flex;
        flex-direction: row;
        align-items: center;

        .qm-defect {
          margin-left: $qm-margin-m;
        }
      }
    }

    .photos-attribute {
      flex-direction: column;

      .value-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $qm-margin-xs;
        margin-top: $qm-margin-xs;

        img {
          &.is-clickable {
            cursor: pointer;
          }
          height: 200px;
          width: auto;
          max-width: 300px;
          border-radius: $qm-border-radius-s;
          object-fit: cover;
        }
      }
    }

    .documents-attribute {
      flex-direction: column;

      .value-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $qm-margin-xs;
        margin-top: $qm-margin-xs;
      }
    }

    .defect-container + .defect-container {
      margin-top: $qm-margin-m;
    }
  }

  .measurements-attribute {
    .value-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 400px;
      gap: $qm-margin-xs;
    }

    .failed {
      font-family: MaisonNeue-Bold;
      font-weight: bold;
    }
  }

  .table-cell {
    padding: 0;
    padding-right: $qm-margin-m;
    padding-bottom: $qm-margin-m;

    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &.id-cell {
      font-size: 16px;
      line-height: 22px;
    }

    &.question-type-cell {
      text-transform: capitalize;
    }

    &.sample-size-cell {
      text-align: right;
    }

    &.body-cell {
      grid-column-start: question;
      grid-column-end: end;
      padding-bottom: $qm-margin-m;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .attributes-container .answer-attribute {
    > .value {
      flex: 1 1 auto;
    }
    .answer-value-items {
      .answer-value-item {
        .answer-deviation {
          color: green;
          &.exceeds-tolerance {
            color: red;
          }
        }
      }
    }
  }

  .attributes-container .qm-answer-detail-values-debug-wrap {
    > button {
      margin: 1rem 0;
    }
    .qm-answer-detail-values-debug {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
      .avItem {
        padding: 0.5rem;
        font-size: 0.85rem;
        border: 1px solid #888;

        > div {
          font-weight: 900;
          > span:first-child {
            font-size: 0.85em;
            font-weight: 300;
          }
        }
      }
    }
  }
}
