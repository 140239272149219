@import 'styles/variables/_colors';

.qm-date-picker-container {
  .qm-input {
    &[disabled] {
      color: $qm-color-text-tertiary;
      border: none;
      cursor: not-allowed;
    }

    &.is-invalid {
      &[type='date'] {
        background-position: right 40px bottom 50%;
      }
    }
  }
}
