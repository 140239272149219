@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.delete-button {
  display: flex;
  align-items: center;
  gap: $qm-margin-xs;
  height: $qm-input-height;
  border: none;
  padding: 0;
  font-family: MaisonNeue-Medium;
  font-size: 16px;
  font-weight: 500;
  color: #a2191f;
  background: transparent;
  cursor: pointer;

  svg {
    color: $qm-color-delete;
    height: 24px;
    width: auto;
  }

  &.disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.2;
    }
  }
}
