@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.assets-detail-condensed-table {
  .assets-table {
    display: table;

    th {
      color: $qm-color-text-tertiary;
      font-family: MaisonNeue-Medium;
      font-size: 14px;
      font-weight: 500;
      height: 18px;
      padding-bottom: 8px;
    }

    td {
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      height: 22px;
    }

    thead {
      border-bottom: 1px solid $qm-gray-lighter;
    }

    th,
    td {
      text-align: left;
      padding: 12px 16px;

      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  .custom-measurements {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
}
