@import 'styles/variables/_colors.scss';

.settings-defect-form {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  .input-info {
    margin-bottom: 0;
  }

  .qm-button.medium-button {
    transform: translateY(5px);
  }

  .link-button {
    color: $qm-color-primary;
    font-family: MaisonNeue-Medium;
    font-size: 14px;
    font-weight: 500;
    height: 18px;
    transform: translateY(12px);

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
