@import '~styles/variables/colors';

.tabs-item {
  color: $qm-color-text;
  background: none;
  border: none;
  font-family: 'MaisonNeue-Medium';
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 4px 0;

  &:not(:last-of-type) {
    margin-right: 32px;
  }

  &.active {
    border-bottom: 3px solid $qm-color-selected;
  }

  &.disabled {
    color: $qm-color-text-tertiary;
    cursor: not-allowed;
  }
}
