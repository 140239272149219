@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.dashboard-page-header {
  padding-top: $qm-padding-m;
  padding-bottom: $qm-margin-m;
  padding-right: $qm-padding-m;

  h2 {
    margin-bottom: $qm-padding;
  }

  .card-container {
    display: flex;
    flex-direction: row;
    gap: $qm-padding-m;
  }

  .small {
    color: $qm-color-text-tertiary;
    font-family: MaisonNeue-Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

.dashboard-page {
  padding-bottom: $qm-padding-l;

  .empty {
    padding-left: $qm-padding-m;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $qm-padding-m;
    padding-bottom: $qm-padding-s;

    &:not(:first-child) {
      padding-top: $qm-padding-l;
    }

    .qm-button {
      margin-left: $qm-padding-m;
    }
  }
}
