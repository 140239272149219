@import 'src/styles/variables/_colors.scss';

.qm-warning-pill-container {
  display: inline-block;

  .qm-warning-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $qm-color-warning;
    min-width: 24px;
    gap: 4px;

    svg {
      color: $qm-white;
      height: 14px;
      width: 14px;
    }

    &:not(.has-text) {
      padding: 0;
      height: 24px;
    }
  }

  .qm-warning-text {
    color: $qm-white;
  }
}
