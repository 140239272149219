@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.qm-radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $qm-padding-ms;

  .option {
    gap: $qm-padding-s;
    padding: $qm-padding-s;
    height: $qm-input-height;

    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    border: 1px solid transparent;
    transition: border 0.1s;

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $qm-color-input;
      color: $qm-color-text;
      box-shadow: none;
    }

    &:hover,
    &:focus {
      border-color: $qm-color-selected;
    }

    &.disabled {
      color: $qm-color-text-tertiary;
      cursor: not-allowed;
      border-color: transparent;
    }

    .radio-check {
      width: 18px;
      height: 18px;

      &.unchecked {
        border: 1px solid #c1c7cd;
        border-radius: 50%;
      }

      &.checked {
        color: $qm-color-primary;
      }
    }
  }

  &.hide-checkbox {
    .option {
      padding: $qm-padding-s $qm-padding-ms;
    }

    .option.selected {
      color: $qm-white;
      background-color: $qm-color-selected;
    }
  }

  &.is-invalid {
    .option {
      border: 1px solid $qm-color-error;
    }
  }
}
