@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.new-password-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  .new-password-form-container {
    margin: auto;

    .new-password-form {
      display: flex;
      justify-content: center;
    }
  }
}
