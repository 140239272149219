.answers-table {
  width: 100%;

  .table-header .table-cell {
    &.id-cell {
      width: 50px;
      height: 16px;
      padding-bottom: 11px;
      vertical-align: baseline;
    }
    .name-cell {
      height: 16px;
      padding-bottom: 11px;
    }
  }

  .qm-input-container .qm-input .answers-group {
    width: 180px;
  }

  .container-m {
    padding: 24px 0;
  }

  p {
    color: black;
  }
}
