@import 'styles/variables/_sizes';

.qm-tooltip {
  $arrow-half-width: 6px;
  $arrow-offset: -($arrow-half-width - 1);
  $tooltip-bg-color: #3d3d3d;

  position: relative;

  font-family: 'MaisonNeue-Book';
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;

  background-color: $tooltip-bg-color;
  color: #fff;
  border-radius: $qm-border-radius-s;
  outline: 0;

  transition-property: transform, visibility, opacity;

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;

    &:before {
      bottom: $arrow-offset;
      left: 0;
      border-width: $arrow-half-width $arrow-half-width 0;
      border-top-color: initial;
      transform-origin: center top;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;

    &:before {
      top: $arrow-offset;
      left: 0;
      border-width: 0 $arrow-half-width $arrow-half-width;
      border-bottom-color: initial;
      transform-origin: center bottom;
    }
  }

  &[data-placement^='left'] > .tippy-arrow {
    right: 0;

    &:before {
      border-width: $arrow-half-width 0 $arrow-half-width $arrow-half-width;
      border-left-color: initial;
      right: $arrow-offset;
      transform-origin: center left;
    }
  }

  &[data-placement^='right'] > .tippy-arrow {
    left: 0;

    &:before {
      left: $arrow-offset;
      border-width: $arrow-half-width $arrow-half-width $arrow-half-width 0;
      border-right-color: initial;
      transform-origin: center right;
    }
  }

  .tippy-arrow {
    width: $arrow-half-width * 2;
    height: $arrow-half-width * 2;
    color: $tooltip-bg-color;

    &:before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }

  .tippy-content {
    position: relative;
    padding: 4px 8px;
    z-index: 1;
  }
}
