@import '~styles/variables/colors';

.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .page-number {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    border: none;
    background-color: $qm-white;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      cursor: auto;
      border-bottom: 2px solid $qm-color-primary;
    }
  }

  .pagination-button-group {
    display: flex;
    margin-right: 24px;
    gap: 12px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
