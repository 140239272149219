@import 'src/styles/variables/sizes';
@import 'src/styles/variables/colors';

.qm-card {
  border-radius: $qm-border-radius-s;
  border: 1px solid $qm-gray-lighter;
  display: flex;
  flex-direction: row;
  align-items: center;

  .image-container {
    overflow: hidden;
    border-radius: $qm-border-radius-s;
    margin: $qm-margin-xs;
    position: relative;
    width: 106px;
    height: 106px;

    img {
      object-fit: cover;
      object-position: center;

      width: 106px;
      height: 106px;
    }
  }

  .body {
    margin-top: $qm-margin-m;
    margin-bottom: $qm-margin-m;
    margin-left: $qm-margin-m;
    display: flex;
    flex-direction: column;

    .body-title {
      color: $qm-color-text;
      font-family: MaisonNeue-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .body-subtitle {
      color: $qm-color-text-secondary;

      strong {
        color: $qm-color-text;
      }

      font-size: 14px;
      line-height: 18px;
    }

    .body-description {
      margin-top: 8px;
    }

    .bold {
      color: rgb(22, 22, 22);
      font-family: 'MaisonNeue-Bold';
    }
  }

  .image-container + .body {
    margin-left: $qm-margin-xs;
  }
}
