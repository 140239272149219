@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.inspection-results-page {
  h2 {
    display: flex;
    align-items: center;

    gap: $qm-padding-ms;
  }

  .defect-summary {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    // width: calc(100% + 2px);
    margin: -1em 0 0 -1em;

    > div {
      display: flex;
      margin: 1em 0 0 1em;
      min-width: 12em;
      max-width: 25%;
    }
    // margin-top: $qm-margin-xs;

    // > * + * {
    //   margin-left: $qm-margin-m;
    // }
  }

  .page-header-container {
    display: flex;
    flex-direction: row;

    .inspection-actions-container {
      display: flex;
      flex-direction: row;
      gap: $qm-margin-m;
      margin-left: auto;
    }
  }

  h4 {
    margin-bottom: $qm-margin-m;
  }

  .combined-inspection-results-info {
    margin-top: 30px;
  }

  .defects-block-layout {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    border-left: 1px solid #bbb;
    > div {
      border-right: 1px solid #bbb;
      padding: 0.25rem 0.5rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .no-asset-snapshot-warning {
    margin-top: $qm-margin-m;
  }

  .inspection-results-table {
    height: 1px;
    margin-top: 1em;
    margin-bottom: 1em;
    border: 1px solid #bbb;
    .table-row {
      .table-cell {
        height: 100%;
        border-bottom: 1px solid #bbb;
        &.col-defects {
          .defects-block-layout {
            height: 100%;
          }
        }
      }
    }
    .table-cell,
    .table-cell:last-child .table-cell:first-child {
      padding: 0px 15px;
    }
    .table-header {
      .table-cell {
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;

        .defects-title-cell {
          padding-top: 0.5em;
          text-align: center;
          text-transform: uppercase;
          .defects-block-layout {
            margin-top: 0.5rem;
            border-top: 1px solid #bbb;
          }
        }
      }
    }

    .col-inspection-block {
      padding-top: 10px;
      padding-bottom: 10px;
      .info-block-id {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        a.inspection-id {
          font-size: 1.15em;
        }
        .status-pill {
          margin-left: 0.5em;
          margin-right: 0.5em;
          display: inline-flex;
        }
        .inspection-name {
          flex: 0 1 auto;
          max-width: 20em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .info-block-aql {
        color: #888;
        font-size: 0.85em;
        line-height: 1.1;
      }

      .info-block-date {
        color: #888;
        font-size: 0.85em;
        line-height: 1.1;
      }
    }
  }

  &.inspection-results-page-multi {
    .questions-container {
      .inspection-wrap {
        margin-bottom: 1em;

        .inspection-title {
          background: #ddd;
          padding: 0.25em 0.5em 0.3em 0.5em;
        }
        .inspection-groups-wrap {
          margin-top: 1em;
        }
      }
    }
  }

  .questions-container {
    margin-top: $qm-margin-m;

    > * {
      margin-bottom: $qm-margin-m;
    }
  }

  .question-cell {
    white-space: pre-wrap;
  }

  .inspeciton-plan-info {
    // TODO: Create mixins lib
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    padding-top: $qm-padding-m;
    padding-bottom: $qm-padding-m;

    .inspection-plan-summary {
      flex: 1 1;
      max-width: 250px;

      .attribute {
        font-size: 16px;
        line-height: 22px;

        > * {
          display: inline-block;
        }

        &:not(:first-child) {
          margin-top: $qm-margin-xs;
        }

        .value {
          font-family: MaisonNeue-Bold;
          font-weight: 500;
        }

        .label {
          font-family: MaisonNeue-Book;
          font-weight: 300;
          color: $qm-color-text-secondary;

          &:after {
            content: ': ';
            white-space: pre;
          }
        }
      }
    }

    .aql-overview-table {
      flex: 1 1;
      max-width: 450px;

      table-layout: auto;
      width: 100%;
      color: $qm-color-text-secondary;

      caption {
        color: $qm-color-text;
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        font-size: 1rem;
        text-align: left;
        padding: 0 0.75rem 0.25rem 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 0;
      }

      td,
      th {
        padding: 0.25rem 0.75rem;
        border-collapse: collapse;
        text-align: center;

        &:first-child {
          white-space: nowrap;
          text-align: left;

          padding-left: 0;
        }
      }

      th {
        padding-bottom: 1rem;
      }

      td.actual-defects-count {
        .qm-defect .pill {
          background: $qm-color-success;
        }
        &.is-rejected .qm-defect .pill {
          background: $qm-color-error-light;
        }
      }
    }
  }

  .inspection-summary {
    flex: 1 1 auto;

    .cards {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      margin-bottom: 24px;

      a {
        color: inherit;
        text-decoration: none;
      }

      .card-column {
        flex: 1;
        max-width: 580px;
      }
      h5 {
        color: $qm-color-text;
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 8px;
      }

      h5.header-with-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $qm-padding-s;
      }

      .actions {
        display: flex;
        gap: $qm-padding-s;
      }

      .qm-card {
        .image-container {
          width: 80px;
          height: 80px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .body {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }

      .source-info {
        display: flex;
        margin-bottom: 16px;

        div {
          margin-left: 4px;
        }
      }

      .work-object-info {
        margin-bottom: 24px;
      }

      .inspection-info {
        display: flex;
        gap: 80px;
        flex-wrap: wrap;

        & > div > p {
          margin-bottom: 6px;
        }
      }

      .dates-container {
        display: flex;
        flex-direction: column;
        gap: $qm-margin-m;
      }

      .pill-container {
        display: flex;
        align-items: baseline;

        svg {
          stroke-width: 3;
          margin-right: 3px;
        }

        .status-pill {
          white-space: nowrap;
        }
      }
    }

    .attributes {
      display: flex;
      flex-wrap: wrap;
      column-gap: 60px;
      row-gap: $qm-margin-m;

      .attribute {
        &-title {
          margin-bottom: 8px;
          &::after {
            content: ': ';
          }
        }
        &-value {
          color: $qm-color-text;
          font-family: MaisonNeue-Bold;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;

          .source-location {
            font-family: MaisonNeue-Book;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .section-usage-decisions-override {
    margin-top: 2em;

    h4 {
      margin-bottom: 0.25em;
    }

    .inspection-usage-decisions-override {
      margin-top: 1em;
    }
  }

  @media print {
    h4 {
      margin-top: $qm-margin-l;
      margin-bottom: 0;
    }
    body {
      // font-size: 80%;
      transform: scale(0.8);
    }

    .page-header-container .inspection-actions-container,
    .hide-checkbox,
    .inspection-plan-summary {
      display: none;
    }

    .inspection-summary {
      display: initial;
    }
    .questions-container {
      margin-top: 0;
    }
  }

  .inspection-summary-container {
    margin-bottom: 32px;

    p {
      white-space: pre-wrap;
      max-width: 65ch;
    }
  }
}
