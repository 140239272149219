@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.work-objects-detail-page {
  padding-bottom: 40px;

  .work-object-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 40px 0 40px;
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .buttons-container {
    display: flex;
    column-gap: 40px;
  }

  .header-info {
    display: flex;
    column-gap: 24px;
    margin-bottom: 24px;
    padding: 0 40px;

    p {
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      color: $qm-color-text-tertiary;
      margin: 0;
    }
  }

  .work-object-target {
    margin: 0 40px 84px 40px;
    max-width: 555px;
  }

  .tabs-container {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 32px;
  }

  .tabs-item {
    padding: 4px 12px;
    text-align: left;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 2px;

    &.success {
      background: $qm-color-success;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .tab-info {
    font-family: 'MaisonNeue-Book';
    font-weight: 300;
    color: $qm-color-text-secondary;
  }
  .tabs-content {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    font-family: 'MaisonNeue-DemiBold';
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  a {
    font-family: 'MaisonNeue-Medium';
    font-weight: 500;
    color: $qm-color-text-link;
    text-decoration: none;
  }

  .custom-fields {
    margin: 24px 0;
    padding: 0 40px;

    p {
      font-family: 'MaisonNeue-Book';
      font-size: 16px;
      line-height: 22px;

      strong {
        white-space: pre-wrap;
      }
    }
  }

  .tabs-content-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 32px;
    padding: 40px 0 20px 0;
  }

  .edit-partial-button {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    height: 48px;
    border: none;
    color: $qm-color-primary;
    padding: 0;
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    background: transparent;
    cursor: pointer;

    svg {
      height: 24px;
      width: auto;
    }
  }

  .text-separator {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    color: $qm-color-text-tertiary;
  }

  &--schedule-inspection-modal {
    width: 576px;

    .body {
      padding-left: $qm-padding-m;
      padding-right: $qm-padding-m;

      p {
        margin-bottom: $qm-margin-m;
        max-width: 40ch;
      }

      .textarea-info .chars-left {
        text-align: right;
      }
    }

    .header {
      margin-bottom: 0;
    }

    .footer {
      display: flex;
      flex-direction: row;
      gap: $qm-padding;
    }
  }

  &--decision-modal {
    min-width: 576px;

    .body {
      padding-left: $qm-padding-m;
      padding-right: $qm-padding-m;

      p {
        margin-bottom: $qm-margin-m;
      }

      .textarea-info .chars-left {
        text-align: right;
      }
    }

    .header {
      margin-bottom: 0;
    }

    .footer {
      display: flex;
      flex-direction: row;
      gap: $qm-padding;
    }
  }
}
