@import 'src/styles/variables/_sizes.scss';

.settings-company-attributes-list-page {
  .settings-company-attributes-table {
    margin-top: $qm-margin-m;
  }
}

.settings-company-attributes-list-page--add-modal {
  max-height: 80vh;

  .add-form-container {
    display: flex;
    flex-direction: row;
    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;
  }

  .company-attribute-option-form {
    margin-left: $qm-padding;
  }
}

.settings-company-attributes-list-page,
.settings-company-attributes-list-page--add-modal {
  .qm-input-container {
    width: 250px;
  }

  .input-info {
    display: none;
  }

  .invalid-feedback {
    margin-bottom: 0;
  }

  .company-attribute-option-form,
  .company-attribute-name-form {
    .cancel-form-button {
      height: $qm-button-height-m;
    }

    .save-form-button,
    .cancel-form-button {
      margin-top: ($qm-input-height - $qm-button-height-m) / 2;
    }

    .delete-option-button {
      height: $qm-input-height;
    }

    > * + * {
      margin-top: 12px;
    }

    .form-row.flex {
      display: flex;
      flex-direction: row;
      align-items: top;

      > * + * {
        margin-left: 24px;
      }
    }
  }
}
