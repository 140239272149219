.settings-asset-types-modal {
  min-width: 350px;
  min-height: 250px;
  font-family: 'MaisonNeue-Medium';

  .header .title {
    align-items: center;
  }
  .body {
    padding: 0 40px;
    overflow: none;
  }

  .error-bag .invalid-feedback {
    margin-bottom: 0;
  }
}
