@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.settings-page {
  .settings-header {
    display: flex;
    gap: $qm-padding;
    margin-bottom: $qm-margin-s;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }

  .settings-page-body {
    display: flex;
    flex-direction: row;

    .settings-content {
      flex: 1;
    }
  }

  .settings-page-header {
    padding-bottom: 0;
    margin-bottom: $qm-margin-l;
  }

  .container-m + .container-m {
    padding-top: 0;
  }

  h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $qm-margin-s;

    .qm-button {
      margin-left: $qm-padding;
    }
  }

  p {
    max-width: 400px;

    &.form-info {
      color: $qm-color-text-secondary;
      font-family: MaisonNeue-Medium;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: $qm-margin-l;

    .search-input-container {
      .input-info {
        display: none;
      }

      width: 200px;
    }

    > * + * {
      margin-left: $qm-padding;
    }
  }

  .settings-content {
    flex: 1;
  }

  .qm-table-controls {
    padding: $qm-padding-m 0;
  }

  .table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $qm-padding-m 0;

    .page-size {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 22px;

      .page-size-select {
        margin-left: 8px;
        width: 146px;
      }
    }
  }

  .settings-timestamps {
    font-family: MaisonNeue-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
}
