@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.assets-form {
  font-family: MaisonNeue-Medium;

  .description-container {
    width: 440px;
  }

  .form-row {
    margin-bottom: $qm-padding;

    .attach-button {
      margin-top: $qm-margin-m;
    }
  }

  .company-attributes-container {
    .input-row:first-of-type {
      margin-top: $qm-padding;
    }
  }

  .type-input-container,
  .status-input-container {
    .select-input {
      max-width: 280px;
    }
  }

  .input-row {
    width: 280px;
  }

  .section-label {
    color: $qm-color-text;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: $qm-margin-xs;
  }

  .error-bag {
    margin-top: 8px;
    max-width: 440px;
  }

  .field-description {
    max-width: 440px;
  }

  .qm-textarea {
    height: 200px;
  }

  .qm-button {
    white-space: nowrap;
  }

  .add-owner {
    margin-top: 32px;
  }

  .measure-button {
    margin-top: 16px;
  }

  .image-preview-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: $qm-padding-ms;
    margin-top: 24px;

    .asset-image {
      position: relative;

      img {
        border: 1px solid rgb(224, 224, 224);
        border-radius: 4px;
        object-fit: contain;
        height: 124px;
        width: 124px;
        background-color: transparent;
      }

      .remove-button {
        position: absolute;
        top: -8px;
        right: -8px;
        height: 16px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        color: $qm-color-delete;

        svg {
          height: 16px;
        }
      }
    }
  }

  .list-controls {
    display: flex;
    margin-top: 8px;
    gap: 32px;
  }

  .custom-field-container {
    .custom-field-item {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      margin-top: 32px;

      .qm-input-container div {
        width: 280px;
        line-height: 24px;
      }

      .delete-custom-field {
        transform: translateY(24px);
      }
    }

    .select-input {
      width: 275px;
    }
    .error-bag .invalid-feedback {
      margin-bottom: 1px;
    }
    .qm-input {
      &:not(.is-invalid) {
        margin-bottom: 1px;
      }
    }
  }

  .document-item {
    .error-bag .invalid-feedback {
      transform: translateX(58px);
    }
  }

  .add-custom-field {
    margin-top: 32px;
  }

  .owners-container {
    .form-label {
      margin-bottom: $qm-margin-m;
    }

    .asset-info {
      margin-bottom: 16px;
    }

    .list-controls {
      margin-top: $qm-padding;
    }

    .owners-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $qm-padding;
      margin-top: $qm-margin-m;

      .owner {
        display: flex;
        gap: $qm-padding-ms;
        align-items: center;

        .remove-button {
          height: 20px;
          background-color: transparent;
          border: none;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .reference-document {
    .section-label {
      margin-bottom: $qm-margin-m;
    }

    .reference-item {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: $qm-padding;

      .qm-file {
        flex-direction: row;
      }

      .input-info {
        margin-bottom: 0;
      }

      .delete-custom-field {
        margin-left: $qm-padding-ms;
      }
    }
  }

  .measure-table {
    h2 {
      margin: 0;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 8px;
      font-family: 'MaisonNeue-Medium';
    }

    .qm-input-container .input-info {
      margin-bottom: 0;
    }

    .description {
      color: $qm-color-text-tertiary;
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;
    column-gap: 32px;
  }
}
