@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.inspection-plan-print-page {
  article {
    width: 8.5in;
    font-size: 10pt;

    h2 {
      font-size: 16pt;
      color: $qm-color-text;
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      background-color: #fff;
    }

    .strike-through {
      position: relative;
      text-align: center;

      > * {
        width: fit-content;
        margin: 0 auto;
        background: #fff;
        position: relative;
        padding-left: 2em;
        padding-right: 2em;
      }

      &::before {
        content: '';
        border-top: 1pt solid $qm-color-bg;
        width: 100%;
        height: 0;
        display: block;
        position: absolute;
        top: 50%;
      }
    }

    .company-name {
      margin-bottom: 0.5em;
    }

    .company-address,
    .company-contact {
      font-size: 9pt;
      font-family: MaisonNeue-Book;
      font-weight: 300;
      color: $qm-color-text-secondary;

      > * {
        line-height: 1.5em;
      }
    }

    .company-phone {
      &::before {
        display: inline-block;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-call'%3E%3Cpath d='M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'%3E%3C/path%3E%3C/svg%3E");
        vertical-align: top;
        margin-right: 0.5em;
      }
    }

    .company-email {
      &::before {
        display: inline-block;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mail'%3E%3Cpath d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E");
        vertical-align: top;
        margin-right: 0.5em;
      }
    }

    header {
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;

      h3 {
        flex: 0;
        flex-basis: 100%;
      }

      .company-address,
      .company-contact {
        flex: 1;
      }
    }

    .boxed-section {
      border: 1pt solid $qm-color-bg;
      border-radius: 5pt;
      padding: 1em;
    }

    .plan-title {
      margin: 0 0 1rem 0;
      padding: 0;
      line-height: 1em;
    }

    .plan-description {
      max-width: 40ch;
      font-size: 12pt;
      line-height: 1.25em;
      margin-bottom: 1rem;
    }

    .plan-subtitle {
      font-size: 10pt;

      label {
        margin-right: 0.5ch;
      }

      > span {
        margin-right: 1em;
      }
    }

    .plan-attributes {
      display: flex;
      flex-wrap: wrap;
      gap: 2em;
      margin-top: 2em;
      margin-bottom: 2em;
      font-size: 10pt;

      .attribute {
        label {
          &::after {
            content: ': ';
          }
        }
      }
    }

    .question-group {
      margin-top: 2rem;
      width: 100%;

      h4 {
        font-size: 14pt;
        border-bottom: 1pt solid $qm-color-bg;

        span {
          font-size: 9pt;
          font-family: MaisonNeue-Book;
          font-weight: normal;
          margin-left: 1em;
          color: $qm-color-text-secondary;
        }
      }
    }

    .question-order {
      padding-top: 1em;
      padding-right: 1em;
      width: 30pt;
    }

    .question-name {
      padding-top: 1em;
      padding-bottom: 0.5em;

      p {
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        max-width: 60ch;
        font-size: 10pt;
      }

      &.missing-translation p {
        color: $qm-color-text-secondary;
        font-family: MaisonNeue-MediumItalic;
        font-weight: 500;
      }
    }

    .question-aql {
      text-align: right;
    }

    .question-body {
      td {
        padding-bottom: 1em;
      }

      .attribute-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20pt;

        > div {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 5pt;
        }

        .primary-attributes {
          flex: 1;
        }

        .secondary-attributes {
          flex: 1;
        }

        .tertiary-attributes {
          flex: 1;
        }
      }

      .attribute {
        &.column {
          display: flex;
          flex-direction: column;
        }

        label {
          color: $qm-color-text-secondary;
        }

        strong {
          li {
            list-style: '🞄 ';
          }
        }
      }
    }
  }
}
