@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.tag-matching-page {
  h2 {
    margin-bottom: $qm-margin-m;
  }
  .container-m.row.fill {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  .playground-container {
    display: flex;
    flex-direction: row;
    flex: 1;

    .settings-panel {
      flex: 0 0 240px;
    }

    .whiteboard-container {
      flex: 1;
      position: relative;
      overflow: hidden;

      > svg {
        position: absolute;
        cursor: grab;
      }
    }
  }

  .flex {
    display: flex;
    gap: $qm-margin-xs;

    .select-input {
      flex: 1;
    }
  }

  .coordinator-source-link {
    stroke: $qm-color-error;
  }
  .coordinator-inspector-link {
    stroke: #f1c21b; // $qm-color-warning;
  }
  .inspector-source-link {
    stroke: $qm-color-success;
  }
  .vendor-source-link {
    stroke: #6b9aff;
  }
  .form-row {
    margin-bottom: $qm-margin-m;
  }
}
