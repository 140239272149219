@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $qm-margin-m;
  border: 1px solid $qm-gray-lighter;
  border-radius: $qm-border-radius-s;

  .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-text {
      display: flex;
      flex-direction: column;

      .title {
        font-family: MaisonNeue-DemiBold;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: $qm-color-text;
      }

      .subtitle {
        font-family: MaisonNeue-Medium;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $qm-color-text-tertiary;
      }
    }

    .header-actions {
      margin-left: $qm-padding;
      margin-right: $qm-margin-m;
      display: flex;
      flex: 1;
    }

    .toggle-button {
      margin-left: auto;
      cursor: pointer;
      background-color: rgba($qm-color-primary, 0.1);
      color: $qm-color-primary;
      border: none;
      border-radius: $qm-border-radius-s;
      padding: 0;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }

  .accordion-body {
    margin-top: $qm-margin-m;
  }
}
