.photos-modal {
  max-width: 800px;
  width: 80vw;
  max-height: 100vh;

  .photos-container {
    width: 700px;
  }

  .header {
    padding: 16px 32px 0 32px !important;
    margin-bottom: 0 !important;
  }
  .footer {
    display: none;
  }

  .body {
    padding: 0 40px 32px 40px;
  }
}
