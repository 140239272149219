@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-switch {
  .off,
  .on {
    border-radius: $qm-border-radius-m - 1px;
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 7px 12px;
    transition: background-color 0.1s;
  }

  .switch-control {
    display: flex;
    flex-direction: row;
    width: max-content;
    height: $qm-input-height;
    border-radius: $qm-border-radius-m;
    background-color: $qm-color-input;
    padding: 4px;
    color: $qm-color-text;
    border: 1px solid transparent;
    user-select: none;
    cursor: pointer;
    transition: border 0.1s;

    &:hover,
    &:focus {
      border-color: $qm-color-selected;
    }
  }

  input {
    display: none;

    &:focus + .switch-control {
      box-shadow: 0px 2px 6px 0px rgba($qm-color-primary, 0.11);
      border: 1px solid $qm-color-primary;
    }

    &:disabled {
      & + .switch-control {
        cursor: not-allowed;
        color: $qm-color-text-tertiary;
      }

      &:checked + .switch-control > .on {
        background-color: $qm-gray-lighter;
      }

      &:not(:checked) + .switch-control > .off {
        background-color: $qm-gray-lighter;
        color: $qm-color-text-tertiary;
      }
    }

    &:disabled + .switch-control {
      border-color: transparent;
    }

    &:checked + .switch-control > .on {
      background-color: $qm-color-primary;
      color: $qm-white;
    }

    &:not(:checked) + .switch-control > .off {
      background-color: $qm-color-primary;
      color: $qm-white;
    }
  }
}
