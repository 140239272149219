.search-input-container {
  svg {
    width: 19px !important;
    height: 19px !important;
    top: 11px;
    left: 14px;
    color: #d7d7d7;
  }

  .search-input {
    &::placeholder {
      font-family: 'MaisonNeue-MediumItalic';
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
