@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.header {
  .bx--row {
    margin-right: 0;
  }

  .profileMenu {
    .bx--dropdown--inline .bx--list-box__menu {
      min-width: 0;
    }
  }
}
.search {
  border-radius: 20px;
  font-family: 'Maison Neue';

  .bx--search-magnifier {
    left: 2em;
    width: 1.4em;
    height: 1.4em;
    fill: $qm-color-bg;
  }

  .bx--search-input {
    border-radius: 6px;
    border: 1px solid $qm-gray-lighter;
    max-width: 290px;
  }

  .bx--search-close {
    display: none;
  }

  input {
    font-size: 1.2em;

    &::placeholder {
      font-style: italic;
      font-weight: 500;
      color: #a8a8a8;
    }
  }
}

.userControls {
  $profileDropdownPadding: 25px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: $qm-padding-m - $profileDropdownPadding;

  & > * {
    margin: 2px 5px;
  }

  .divider {
    height: 40%;
    padding: 0 15px;

    div:first-child {
      width: 50%;
      height: 100%;
      border-right: 1px solid $qm-gray-light;
    }
  }

  .profile {
    color: $qm-color-text;
    font-size: 1.2em;
    white-space: nowrap;
  }
}
