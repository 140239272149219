@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.previous-decisions-container {
  margin-top: $qm-padding;
  font-family: 'MaisonNeue-Book';

  > * + * {
    margin-top: $qm-padding;
  }

  .previous-decision {
    .decision-notes {
      font-size: 14px;
      font-family: 'MaisonNeue-Medium';
      color: $qm-color-text-secondary;
      white-space: pre-wrap;
      margin-top: $qm-margin-s;
    }

    .decision-name {
      margin-top: $qm-margin-s;
      font-family: 'MaisonNeue-Book';
      color: $qm-color-text-tertiary;
    }
  }
}
