@import 'styles/variables/_colors.scss';
@import 'styles/variables/_sizes.scss';

.sources-form {
  .form-row {
    margin-bottom: $qm-padding;
  }

  .error-bag {
    margin-top: 8px;
    max-width: 440px;
  }

  .qm-input-container,
  .qm-textarea-container,
  .tags-select-container {
    width: 440px;
  }

  textarea {
    resize: vertical;
  }

  .type-input-container,
  .country-input-container,
  .state-input-container,
  .city-input-container {
    .select-input,
    .qm-input-container {
      width: 220px;
    }
  }

  .zip-input-container {
    .qm-input-container {
      width: 124px;
    }
  }

  .status-input-container {
    .qm-button {
      text-transform: capitalize;
    }
  }

  .field-description {
    margin-top: $qm-margin-xs;
    max-width: 400px;
  }

  .remove-button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;

    svg {
      color: $qm-color-delete;
    }
  }

  .list-controls {
    display: flex;
    flex-direction: row;
    > * {
      margin-right: $qm-padding;
    }
  }

  .image-preview-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: $qm-padding-ms;

    .source-image {
      position: relative;

      img {
        border-radius: 4px;
        height: 124px;
        background-color: #ccc;
      }

      .remove-button {
        position: absolute;
        top: -8px;
        right: -8px;
        height: 16px;

        svg {
          height: 16px;
        }
      }
    }
  }

  .qm-google-map {
    height: 440px;
    width: 660px;
  }

  .owners-container {
    .form-label {
      margin-bottom: $qm-margin-m;
    }

    .owners-list {
      display: flex;
      flex-direction: row;
      gap: $qm-padding;
      margin-bottom: $qm-padding;

      .owner {
        &.has-errors {
          border: 1px solid $qm-color-error;
          padding: $qm-padding-s;
          border-radius: $qm-border-radius-s;
          margin: -$qm-padding-s;
        }

        .error-bag .invalid-feedback {
          margin-bottom: 0;
        }

        > div {
          display: flex;
          gap: $qm-padding-ms;
          align-items: center;

          .remove-button {
            height: 20px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
