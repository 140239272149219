@import '~styles/variables/colors';

.latest-activity {
  width: 450px;
  padding: 40px 0;

  h2 {
    margin-bottom: 40px;
  }

  .latest-activity-item {
    display: flex;
    padding-bottom: 32px;
    position: relative;
    z-index: 1;

    &:not(:last-of-type) {
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        background: $qm-gray-lighter;
        height: 100%;
        top: 0;
        left: 22px;
        z-index: -1;
      }
    }
  }

  .activity-info-container {
    margin-left: 8px;
  }

  .activity-description {
    font-family: 'MaisonNeue-Book';
    font-size: 16px;
    line-height: 22px;
  }
  .activity-timestamp {
    font-family: 'MaisonNeue-Book';
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: $qm-color-text-secondary;
  }
}
