@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.companies-form {
  h4 {
    font-family: 'MaisonNeue-Medium';
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .qm-input-container,
  .select-input,
  .qm-textarea-container {
    max-width: 440px;
  }

  .form-row {
    margin-bottom: 32px;
  }

  .qm-textarea {
    height: $qm-textarea-height;
  }

  .form-info {
    max-width: 420px;
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $qm-color-text-tertiary;
    margin-top: 12px;
  }

  .zip-code {
    width: 180px;
  }

  .contact-field {
    display: flex;
    column-gap: 32px;

    .qm-input-container {
      width: 440px;
    }
  }

  .delete-contact-field {
    margin-top: 26px;
  }

  .add-contact-field {
    margin-bottom: 40px;
  }

  .submit-container {
    margin-top: 72px;
  }
  .error-bag {
    margin-top: 8px;
  }
  .qm-input-container {
    .left-insert {
      color: $qm-gray-lighter;

      svg {
        height: 20px;
      }
    }
  }
}
