@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-BoldItalic.eot');
  src: local('Maison Neue Bold Italic'), local('MaisonNeue-BoldItalic'),
    url('MaisonNeue-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-BoldItalic.woff2') format('woff2'),
    url('MaisonNeue-BoldItalic.woff') format('woff'),
    url('MaisonNeue-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-Medium.eot');
  src: local('Maison Neue Medium'), local('MaisonNeue-Medium'),
    url('MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Medium.woff2') format('woff2'),
    url('MaisonNeue-Medium.woff') format('woff'),
    url('MaisonNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-Light.eot');
  src: local('Maison Neue Light'), local('MaisonNeue-Light'),
    url('MaisonNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Light.woff2') format('woff2'),
    url('MaisonNeue-Light.woff') format('woff'),
    url('MaisonNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Book';
  src: url('MaisonNeue-Book.eot');
  src: local('Maison Neue Book'), local('MaisonNeue-Book'),
    url('MaisonNeue-Book.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Book.woff2') format('woff2'),
    url('MaisonNeue-Book.woff') format('woff'),
    url('MaisonNeue-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Demi';
  src: url('MaisonNeue-Demi.eot');
  src: local('Maison Neue Demi'), local('MaisonNeue-Demi'),
    url('MaisonNeue-Demi.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Demi.woff2') format('woff2'),
    url('MaisonNeue-Demi.woff') format('woff'),
    url('MaisonNeue-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Demi';
  src: url('MaisonNeue-DemiItalic.eot');
  src: local('Maison Neue Demi Italic'), local('MaisonNeue-DemiItalic'),
    url('MaisonNeue-DemiItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-DemiItalic.woff2') format('woff2'),
    url('MaisonNeue-DemiItalic.woff') format('woff'),
    url('MaisonNeue-DemiItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-Black.eot');
  src: local('Maison Neue Black'), local('MaisonNeue-Black'),
    url('MaisonNeue-Black.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Black.woff2') format('woff2'),
    url('MaisonNeue-Black.woff') format('woff'),
    url('MaisonNeue-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Book';
  src: url('MaisonNeue-BookItalic.eot');
  src: local('Maison Neue Book Italic'), local('MaisonNeue-BookItalic'),
    url('MaisonNeue-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-BookItalic.woff2') format('woff2'),
    url('MaisonNeue-BookItalic.woff') format('woff'),
    url('MaisonNeue-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-ExtraBoldItalic.eot');
  src: local('Maison Neue ExtraBold Italic'),
    local('MaisonNeue-ExtraBoldItalic'),
    url('MaisonNeue-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ExtraBoldItalic.woff2') format('woff2'),
    url('MaisonNeue-ExtraBoldItalic.woff') format('woff'),
    url('MaisonNeue-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue ExtraThin';
  src: url('MaisonNeue-ExtraThinItalic.eot');
  src: local('Maison Neue ExtraThin Italic'),
    local('MaisonNeue-ExtraThinItalic'),
    url('MaisonNeue-ExtraThinItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ExtraThinItalic.woff2') format('woff2'),
    url('MaisonNeue-ExtraThinItalic.woff') format('woff'),
    url('MaisonNeue-ExtraThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-LightItalic.eot');
  src: local('Maison Neue Light Italic'), local('MaisonNeue-LightItalic'),
    url('MaisonNeue-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-LightItalic.woff2') format('woff2'),
    url('MaisonNeue-LightItalic.woff') format('woff'),
    url('MaisonNeue-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue ExtraThin';
  src: url('MaisonNeue-ExtraThin.eot');
  src: local('Maison Neue ExtraThin'), local('MaisonNeue-ExtraThin'),
    url('MaisonNeue-ExtraThin.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ExtraThin.woff2') format('woff2'),
    url('MaisonNeue-ExtraThin.woff') format('woff'),
    url('MaisonNeue-ExtraThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-BlackItalic.eot');
  src: local('Maison Neue Black Italic'), local('MaisonNeue-BlackItalic'),
    url('MaisonNeue-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-BlackItalic.woff2') format('woff2'),
    url('MaisonNeue-BlackItalic.woff') format('woff'),
    url('MaisonNeue-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-ExtraBold.eot');
  src: local('Maison Neue ExtraBold'), local('MaisonNeue-ExtraBold'),
    url('MaisonNeue-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ExtraBold.woff2') format('woff2'),
    url('MaisonNeue-ExtraBold.woff') format('woff'),
    url('MaisonNeue-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-Bold.eot');
  src: local('Maison Neue Bold'), local('MaisonNeue-Bold'),
    url('MaisonNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Bold.woff2') format('woff2'),
    url('MaisonNeue-Bold.woff') format('woff'),
    url('MaisonNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-ThinItalic.eot');
  src: local('Maison Neue Thin Italic'), local('MaisonNeue-ThinItalic'),
    url('MaisonNeue-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ThinItalic.woff2') format('woff2'),
    url('MaisonNeue-ThinItalic.woff') format('woff'),
    url('MaisonNeue-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-Thin.eot');
  src: local('Maison Neue Thin'), local('MaisonNeue-Thin'),
    url('MaisonNeue-Thin.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Thin.woff2') format('woff2'),
    url('MaisonNeue-Thin.woff') format('woff'),
    url('MaisonNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('MaisonNeue-MediumItalic.eot');
  src: local('Maison Neue Medium Italic'), local('MaisonNeue-MediumItalic'),
    url('MaisonNeue-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-MediumItalic.woff2') format('woff2'),
    url('MaisonNeue-MediumItalic.woff') format('woff'),
    url('MaisonNeue-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* DELETE ABOVE */

@font-face {
  font-family: 'MaisonNeue-Bold';
  src: url('MaisonNeue-Bold.eot');
  src: local('Maison Neue Bold'), local('MaisonNeue-Bold'),
    url('MaisonNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Bold.woff2') format('woff2'),
    url('MaisonNeue-Bold.woff') format('woff'),
    url('MaisonNeue-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('MaisonNeue-Book.eot');
  src: local('Maison Neue Book'), local('MaisonNeue-Book'),
    url('MaisonNeue-Book.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Book.woff2') format('woff2'),
    url('MaisonNeue-Book.woff') format('woff'),
    url('MaisonNeue-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-Medium';
  src: url('MaisonNeue-Medium.eot');
  src: local('Maison Neue Medium'), local('MaisonNeue-Medium'),
    url('MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Medium.woff2') format('woff2'),
    url('MaisonNeue-Medium.woff') format('woff'),
    url('MaisonNeue-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-DemiBold';
  src: url('MaisonNeue-Demi.eot');
  src: local('Maison Neue Medium'), local('MaisonNeue-Demi'),
    url('MaisonNeue-Demi.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-Demi.woff2') format('woff2'),
    url('MaisonNeue-Demi.woff') format('woff'),
    url('MaisonNeue-Demi.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-ExtraBold';
  src: url('MaisonNeue-ExtraBold.eot');
  src: local('Maison Neue Extra Bold'), local('MaisonNeue-ExtraBold'),
    url('MaisonNeue-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-ExtraBold.woff2') format('woff2'),
    url('MaisonNeue-ExtraBold.woff') format('woff'),
    url('MaisonNeue-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-MediumItalic';
  src: url('MaisonNeue-MediumItalic.eot');
  src: local('Maison Neue Medium Italic'), local('MaisonNeue-MediumItalic'),
    url('MaisonNeue-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('MaisonNeue-MediumItalic.woff2') format('woff2'),
    url('MaisonNeue-MediumItalic.woff') format('woff'),
    url('MaisonNeue-MediumItalic.ttf') format('truetype');
}
