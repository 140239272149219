@import './styles/variables/colors';
@import './styles/variables/sizes';

html {
  color: $qm-color-text;
  font-family: 'MaisonNeue-Book';
}

body {
  > iframe {
    display: none !important;
  }
}

h1 {
  font-family: 'MaisonNeue-Medium';
  font-size: 54px;
  font-weight: 500;
  line-height: 60px;
}

h2 {
  font-family: 'MaisonNeue-DemiBold';
  font-size: 42px;
  font-weight: 600;
  line-height: 50px;
}

h3 {
  font-family: 'MaisonNeue-DemiBold';
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

h4 {
  font-family: 'MaisonNeue-DemiBold';
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

h5 {
  font-family: 'MaisonNeue-Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

h6 {
  font-family: 'MaisonNeue-Bold';
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

p.large {
  line-height: 26px;
}

p.field-description {
  color: $qm-color-text-tertiary;
  font-family: 'MaisonNeue-Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 18px;
}

.container-m {
  padding: $qm-padding-m;
  // height: 100%;
  width: 100%;
}

.text-right {
  text-align: right;
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.form-label {
  font-family: 'MaisonNeue-Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: $qm-color-text;
  margin-bottom: 6px;
  display: block;
}

.no-translation {
  color: $qm-color-text-tertiary;
}

a {
  color: #6320ee;
}

.App {
  display: flex;
  flex-direction: row;
  padding: 0;
  background-color: $qm-gray-medium;
  margin: 0 auto;

  .debug-views-notice {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em 0.5em;
    background: $qm-color-error;
    color: #fff;
    font-size: 0.65em;
    font-weight: 600;
  }
}

@media print {
  .App,
  html {
    background-color: #fff;
  }
}
