.settings-types-form {
  display: flex;
  gap: 32px;

  .action-buttons {
    display: flex;
    gap: 32px;
    padding-top: 12px;

    .qm-button {
      height: 32px;
    }
  }

  .error-bag {
    .invalid-feedback {
      margin-bottom: 0;
    }
  }
}
