@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.assets-table {
  .asset {
    display: flex;
    max-width: 400px;
    align-items: center;

    span {
      max-width: 65ch;
    }

    .image-container {
      position: relative;
      max-width: 50px;
      max-height: 50px;
      margin-right: $qm-margin-s;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .id-cell {
    text-align: center;
    color: $qm-color-text-secondary;
    font-family: MaisonNeue-Book;
    font-weight: 300;
    font-size: 14px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }
  }

  .align-right {
    .pill-container {
      justify-content: flex-end;
    }
  }

  .align-left {
    .pill-container {
      justify-content: flex-start;
    }
  }

  .pill-container {
    display: flex;

    .status-pill {
      white-space: nowrap;
    }
  }

  .description-cell {
    max-width: 65ch;
  }
}
