@import "~styles/variables/sizes";
@import "~styles/variables/colors";

.usage-decision-notes-modal {
  min-width: 576px;

  .body {
    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;

    p {
      margin-bottom: $qm-margin-m;
    }

    .textarea-info .chars-left {
      text-align: right;
    }

    .warn-note {
      margin-top: 0.25em;
      margin-bottom: 0;
      color: $qm-color-error;
    }
  }

  .header {
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    gap: $qm-padding;
  }

  &.is-override-usage-decision {
    .footer {
      button:not(.link-button) {
        background: $qm-color-error;
        font-weight: 600;

        &:hover {
          background: darken($qm-color-error, 10%);
        }
      }
    }
  }
}
