.re-inspect-modal {
  max-width: 600px;
  width: 60vw;
  max-height: 400px;

  .body {
    // fix select options overflow in modal body
    overflow: visible !important;
  }

  .re-inspect-form {
    padding: 0 40px;

    p {
      margin-bottom: 16px;
    }
  }

  .select-input {
    width: 275px;
  }
  .error-bag {
    margin-top: 8px;
  }
}
