@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.settings-questions-modal {
  max-width: 1000px;
  width: 80vw;
  max-height: 800px;
  height: 80vh;

  .search-input-container {
    width: 330px;
  }

  .qm-table {
    .table-cell.id-cell {
      $checkSize: 18px;
      $checkMargin: 4px;
      display: grid;
      grid-template-columns: $checkSize auto;
      grid-gap: $checkMargin;
      align-items: center;

      padding-left: $qm-padding-m - $checkSize - $checkMargin;

      .id {
        vertical-align: center;
      }

      .check-selected {
        color: #42be65;
        margin-right: $checkMargin;
        width: $checkSize;
        height: $checkSize;
        vertical-align: middle;
      }
    }
  }

  .table-row {
    &.row-selected {
      background-color: #f5f5f5;
    }
  }

  .footer {
    display: flex;
    .pagination-container {
      margin-left: auto;
    }
  }

  .table-header {
    .table-cell {
      white-space: nowrap;
    }
  }

  p {
    max-width: 50ch;
  }
}
