@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.settings-company-attributes-table {
  .expand-button {
    cursor: pointer;
    margin-left: 1ch;
    color: $qm-color-text-link;
    white-space: nowrap;
  }

  .table-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .edit-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
    line-height: 20px;
    margin-left: 1em;
    vertical-align: middle;
    color: $qm-color-text-link;

    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  .id-cell {
    width: 0;
  }

  .table-row {
    &.editing {
      .table-cell {
        vertical-align: top;
      }

      .id-cell {
        line-height: $qm-input-height;
      }

      .actions-cell {
        display: none;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}
