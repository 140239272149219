@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.reset-password-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  .reset-password-form-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .messages {
      margin-bottom: 20px;
    }

    .reset-password-form {
      max-width: 300px;
    }

    .qm-input-container {
      &:first-child {
        .left-insert {
          margin-top: 9.5px;
          margin-left: 9px;
        }
      }
    }
  }
}
