@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.owners-modal {
  max-width: 900px;
  width: 80vw;
  height: 80vh;

  .search-container {
    flex: 0;
    display: flex;
    flex-direction: row;

    .qm-input {
      width: 330px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 16px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-row {
      &.row-selected {
        background-color: #f5f5f5;

        .name-cell {
          $checkSize: 18px;
          $checkMargin: 4px;
          padding-left: $qm-padding-m - $checkSize - $checkMargin;

          .check-selected {
            color: #42be65;
            margin-right: $checkMargin;
            width: $checkSize;
            height: $checkSize;
          }
        }
      }
    }

    .table-cell {
      padding-top: 16px;
      padding-bottom: 16px;

      &.id-cell {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
      }

      &.name-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .roles-cell-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
      }
    }
  }
}
