@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.qm-modal-portal .qm-modal-overlay .qm-modal.linkable-resources-modal {
  max-width: 1250px;
  width: 98vw;
  height: 95vh;
  position: relative;

  .header {
    padding: 30px 30px 0 30px;
  }

  &.multiple-selection {
    .footer {
      justify-content: space-between;
      align-items: center;
    }

    .selected-resources-summary {
      font-size: 14px;
      display: flex;
      align-items: center;
      column-gap: 16px;

      .summary-item {
        display: flex;
        align-items: center;
        column-gap: 4px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .header-container {
    position: absolute;
    top: 24px;
    left: 30px;
    right: 70px;

    .tabs-container {
      padding: 0 0 8px 0;
    }

    &::after {
      position: absolute;
      bottom: 8px;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: #e0e0e0;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px 20px 30px;
  }

  .tabs-item {
    font-size: 22px;
    line-height: 25px;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .qm-input {
      width: 200px;
    }

    .input-info {
      display: none;
    }
  }

  .selected-filters-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    flex-basis: 100%;
    flex-shrink: 0;

    .status-pill {
      cursor: pointer;
      user-select: none;
    }
  }

  .filters-container {
    margin-top: 10px;
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 16px;

    .search-container,
    .filter {
      > label {
        font-size: 0.85rem;
        display: block;
        margin-bottom: 0.25rem;
      }
    }

    .filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      line-height: 22px;

      .filter-select {
        width: 146px;
      }

      .filter-checkbox {
        min-height: 44px;
        margin-bottom: 0;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .checkbox-input {
          input {
            display: none;
          }
        }
      }
    }
  }

  .qm-table {
    border-collapse: separate;

    .table-header {
      .table-cell {
        padding-top: 8px;
        padding-bottom: 8px;
        position: sticky;
        top: 0;
        background-color: $qm-white;
        border-bottom: 1px solid $qm-gray-lighter;
        z-index: 10;
      }
    }

    .table-row {
      &:hover {
        background-color: #f5f5f5;
      }
    }

    .table-cell {
      padding-top: 8px;
      padding-bottom: 8px;

      &.select-cell {
        .checkbox-wrapper {
          padding: 0px 8px 0px 40px;
        }
      }

      &.id-cell {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: $qm-color-text-secondary;
        max-width: 15em;
        overflow-wrap: break-word;
        white-space: normal;
      }

      &.type-cell {
        .pill {
          width: max-content;
        }
      }

      &.name-cell {
        .name {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
          }

          .title {
            max-width: 20em;
            overflow-wrap: break-word;
          }
        }
      }

      .subtitle {
        font-family: 'MaisonNeue-Book';
        font-size: 14px;
        font-weight: 300;
        height: 18px;
        line-height: 18px;
      }
    }
  }
}
