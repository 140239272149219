@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.assets-details-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  h2 {
    margin-right: $qm-margin-m;
    max-width: 700px;
    display: flex;
    align-items: center;

    .status-pill {
      margin-left: $qm-margin-m;
    }
  }

  .assets-header {
    margin-right: $qm-margin-m;
  }

  .id {
    color: $qm-color-text-tertiary;
    font-family: 'MaisonNeue-Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 18px;
  }

  .full-width {
    width: 100%;
  }

  .margin-right {
    margin-right: $qm-margin-m;
  }

  .margin-bottom-m {
    margin-bottom: $qm-margin-m;
  }

  .margin-bottom-l {
    margin-bottom: $qm-margin-m;
  }

  .margin-bottom-xl {
    margin-bottom: 64px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .short-data {
    display: flex;
    align-items: center;
    margin-bottom: $qm-padding;

    & > * {
      margin-right: $qm-margin-m;
    }
  }

  h5 {
    font-family: MaisonNeue-Book;
    font-size: 20px;
    font-weight: 300;
    height: 28px;
    line-height: 28px;
  }

  .row {
    display: flex;
    flex-direction: row;

    &.assets-header {
      justify-content: space-between;
    }

    .details {
      width: 50%;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .user-list {
    display: flex;
    flex-direction: row;

    .qm-named-avatar {
      margin-right: $qm-padding;
    }
  }

  .info-block {
    .block-title {
      font-family: 'MaisonNeue-Book';
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      color: $qm-color-text;
      margin-bottom: 12px;
    }
  }

  .empty {
    font-family: 'MaisonNeue-Book';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: $qm-color-text-secondary;
    max-width: 400px;
  }

  .data-desc {
    flex: 3;
  }

  .assets-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $qm-margin-m;
    position: relative;

    .gallery-container {
      flex: 2;
      display: grid;
      place-items: center;
    }

    .qm-image-gallery {
      width: 400px;
      height: 400px;

      .image-gallery-content .image-gallery-slide-wrapper {
        .image-gallery-swipe,
        .image-gallery-slides {
          height: 250px;
        }
      }

      .image-gallery-thumbnails-wrapper {
        position: relative;
      }

      .image-gallery-content
        .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container {
        display: inline-flex;
        cursor: pointer;
        text-align: center;
        transition: transform 0.45s ease-out;
        white-space: nowrap;
      }

      .image-gallery-content
        .image-gallery-slide-wrapper
        .image-gallery-slides
        .image-gallery-slide
        > div
        img {
        max-height: 250px;
        object-fit: contain;
      }

      .image-gallery-content
        .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container
        .image-gallery-thumbnail {
        width: 125px;
        height: 125px;
      }

      .image-gallery-content
        .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container
        .image-gallery-thumbnail
        .image-gallery-thumbnail-inner
        img.image-gallery-thumbnail-image {
        object-fit: contain;
      }

      .assets-details-page
        .assets-media
        .qm-image-gallery
        .image-gallery-content
        .image-gallery-slide
        .image-gallery-image {
        object-fit: contain;
      }
    }
  }

  .asset-desc {
    max-width: 65ch;
  }

  .custom-fields-container {
    flex: 1;
    display: grid;
    gap: $qm-margin-xs $qm-margin-m;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    .custom-field {
      font-size: 16px;
      line-height: 22px;
      flex: 1;

      .field-label {
        color: $qm-color-text-secondary;
        font-family: MaisonNeue-Book;
        font-weight: 300;

        &::after {
          content: ': ';
          white-space: pre;
        }
      }

      .field-value {
        color: $qm-color-text;
        font-family: MaisonNeue-Bold;
        font-weight: 500;
      }
    }
  }

  .reference-documents {
    display: flex;
    gap: $qm-margin-s;

    a {
      text-decoration: none;
    }
  }

  .padding-container {
    padding: 0 40px;
  }

  .associated-table-title {
    display: flex;
    align-items: center;
    margin-top: 64px;
    padding: 0 40px;

    h2 {
      font-family: 'MaisonNeue-Book';
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      margin-right: 24px;
    }
  }

  .target-date-cell {
    display: table-cell !important;
  }

  .info-message {
    margin-top: 48px;
    text-align: center;
  }
}
