@import '~styles/variables/sizes';

.work-objects-list-page {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .type-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .filter-bar {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 16px;

    .search-container {
      width: 200px;

      .input-info {
        display: none;
      }
    }

    .filters-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 16px;
      z-index: 20;

      .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .filter-select {
          width: 144px;
        }
      }
    }
  }

  .work-objects-list-header {
    margin-bottom: $qm-margin-l;
    padding-bottom: 0;

    .header-buttons {
      display: flex;
      column-gap: 32px;
    }

    h2 {
      width: 50%;
    }
  }

  .workflow-cell {
    display: table-cell !important;
  }
}
