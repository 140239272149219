@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.bulk-inspection-plans-results-header {
  .success {
    color: $qm-color-text-success;
    font-family: MaisonNeue-DemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-top: $qm-margin-s;
    margin-bottom: $qm-margin-s;
  }

  .errors-container {
    padding: $qm-padding-s;
    border-radius: $qm-border-radius-s;
    border: 1px solid $qm-gray-lighter;

    > * {
      display: flex;
      align-items: center;

      span {
        white-space: pre;
      }
    }

    > * + * {
      margin-top: $qm-margin-s;
    }
  }

  line-height: 1.25em;

  svg {
    height: 1.25em;
    width: 1em;
    vertical-align: bottom;
  }

  > * + * {
    margin-top: 0.5em;
  }
}
