@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.qm-question-detail {
  display: contents;

  .question-cell p {
    white-space: pre-wrap;
  }

  .no-translation {
    color: $qm-gray-lighter;
  }

  .attributes-container {
    font-size: 16px;
    line-height: 22px;
    font-family: MaisonNeue-Book;

    > * + * {
      margin-top: $qm-margin-xs;
    }

    .attribute {
      display: flex;
      flex-direction: row;

      .label {
        color: $qm-color-text-secondary;
        font-weight: 300;

        &::after {
          content: ': ';
          white-space: pre;
        }
      }

      .value {
        display: flex;
        flex-direction: column;
        color: $qm-color-text;
        font-family: MaisonNeue-Bold;
        font-weight: bold;
      }

      &.file-list-container {
        flex-direction: column;

        .label {
          margin-bottom: $qm-margin-m;
        }

        .file-list {
          display: flex;
          flex-direction: row;
          margin-bottom: $qm-margin-xs;

          a {
            text-decoration: none;
          }

          .document-link {
            &.disabled {
              cursor: default;
              .icon {
                background-color: change-color(
                  $qm-color-text-secondary,
                  $lightness: 75%
                );
              }
              .name {
                color: $qm-color-text-secondary;
              }
            }
          }

          > * {
            margin-right: $qm-margin-m;
          }
        }
      }
    }
  }

  .edit-question-button {
    background: transparent;
    border: none;
    color: $qm-color-selected;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .table-cell {
    padding: 0;
    padding-right: $qm-margin-m;
    padding-bottom: $qm-margin-m;

    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &.id-cell {
      font-size: 16px;
      line-height: 22px;
    }

    &.body-cell {
      grid-column-start: question;
      grid-column-end: end;
      padding-bottom: $qm-margin-m;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &.is-open {
    .edit-cell {
      grid-row: span 2;
    }
  }
}
