@import '../../../styles/variables/_colors.scss';

.error-bag {
  .error-item:first-letter {
    text-transform: capitalize;
  }

  .invalid-feedback {
    color: $qm-color-error;
    font-size: 13px;
    margin-bottom: 20px;
  }
}
