@import "~styles/variables/colors";

.inspection-plans-table {
  .table-cell {
    &.id-cell {
      font-family: "MaisonNeue-Book";
      font-size: 14px;
      font-weight: 300;
      color: $qm-color-text-secondary;
      white-space: nowrap;

      span {
        line-height: 14px;
        display: inline-block;
      }

      .no-wrap {
        white-space: nowrap;
      }

      .qm-warning-pill-container {
        line-height: 24px;
        margin-right: 8px;
      }
    }

    &.target-cell {
      .qm-card {
        border: none;
        .body {
          margin: 0;

          .body-title {
            font-size: 1rem;
            overflow-wrap: break-word;
            max-width: 12rem;
          }
          .body-subtitle {
            font-size: 0.75rem;
          }
        }
      }
    }

    &.align-right {
      .pill-container {
        justify-content: flex-end;
      }
    }

    &.align-left {
      .pill-container {
        justify-content: flex-start;
      }
    }

    .pill-container {
      display: flex;

      .status-pill {
        white-space: nowrap;
      }
    }
  }
}
