@import '../../../styles/variables/_colors.scss';
@import '../../../styles/variables/_sizes.scss';

.qm-button {
  font-family: 'Maison Neue Book';
  letter-spacing: 0.16px;
  white-space: nowrap;

  background-color: $qm-color-primary;
  border-radius: $qm-border-radius-s;
  height: $qm-button-height;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 11px 22px;

  border: none;

  font-size: 14px;
  color: $qm-white;
  font-weight: 300;

  cursor: pointer;

  &:hover {
    background-color: $qm-color-primary;
  }

  &:focus {
    box-shadow: 0px 2px 6px 0px rgba($qm-color-primary, 0.68);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba($qm-color-primary, 0.2);
    color: rgba($qm-color-primary, 0.5);
  }

  &.outline {
    color: $qm-color-text-link;
    border: 1px solid $qm-color-text-link;
    background: none;
  }

  &.medium-button {
    height: $qm-button-height-m;
  }

  &.link-button {
    background-color: transparent;
    height: unset;
    padding: 0;
    margin: 0;
    display: inline-block;
    text-decoration: underline;
    color: $qm-color-text-link;
  }

  &.outline-button {
    background-color: transparent;
    color: $qm-color-primary;
    border: 1px solid $qm-color-primary;
  }

  .loading {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    border: 2px solid #fff;
    border-radius: 100%;
    display: inline-block;
    border-top: 5px solid transparent;
    animation: load-animate infinite linear 1s;

    @keyframes load-animate {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
        opacity: 0.35;
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
