@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.sideMenu {
  width: 255px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;

  .header-logo {
    height: 44px;
    margin: 0 1.6rem;
    margin-top: 20px;
    color: $qm-color-selected;
    align-self: flex-start;
  }

  .menuContainer {
    margin-top: 40%;
  }

  .qm-company-select-dropdown-container {
    margin: $qm-margin-m;
    margin-top: auto;
  }
}

.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  .header {
    display: block;
    padding: 20px 0;

    .bx--search-input {
      margin-bottom: 0;
      height: 2.8rem;
    }
  }

  > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > div:not(.floating),
    &:empty {
      height: auto;
      flex: 1;
      background-color: white;
      border-top-left-radius: 10px;
      border: 1px solid $qm-gray-lighter;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.07);
      border-right: none;
      border-bottom: none;

      > * {
        flex: 0;
      }
    }
  }
}

@media print {
  .page > .content > div:not(.floating) {
    border: none;
    padding: 0;
    box-shadow: none;
  }
  .sideMenu,
  .page > .header {
    display: none;
  }
}
