@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.work-objects-table {
  .warning-id {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .progress-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: $qm-margin-xs;

    .percent {
      flex: 0;
      text-align: right;
      min-width: 5ch;
    }

    .progressBar {
      flex: 1;
      min-width: 30px;
    }
  }

  .primary-text {
    color: $qm-color-text;
    font-family: MaisonNeue-Medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
  }

  .secondary-text {
    color: $qm-color-text-secondary;
    font-family: MaisonNeue-Book;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.16px;
    line-height: 18px;
  }

  .image-name {
    display: flex;
    align-items: center;

    .image-container {
      position: relative;
      width: 44px;
      height: 44px;
      margin-right: $qm-margin-s;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .target-date-cell,
  .workflow-cell {
    display: none;
  }
}
