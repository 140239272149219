@import '~styles/variables/colors';

.timeline-new-subitem {
  position: absolute;
  bottom: 20px;
  left: 8px;
  z-index: 1;

  .timeline-new-subitem-button {
    padding: 3px;
    width: 24px;
    height: 24px;
    border: 1px solid $qm-color-text-link;
    border-radius: 50%;
    background: #ffffff;
    color: $qm-color-text-link;
    cursor: pointer;
  }

  .new-subitem-icon {
    width: 16px;
    height: 16px;
  }
}
