@import 'src/styles/variables/_sizes';

.settings-question-groups-list-page {
  .question-groups-container {
    margin-top: $qm-margin-m;

    > * + * {
      margin-top: $qm-padding-ms;
    }
  }
  .settings-question-dependency-group {
    .body-cell {
      padding-top: 0;

      .dependency-container {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.settings-question-groups-list-page--add-modal {
  width: 80vw;
  max-width: 1200px;
  height: 80vh;

  .qm-settings-question-group {
    border: none;
    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;
  }

  .question-actions-container {
    display: flex;
    flex-direction: row;
    > * + * {
      margin-left: $qm-padding;
    }
  }
}
