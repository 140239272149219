@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

.inspection-plans-form {
  .form-row {
    margin-bottom: $qm-padding;
    width: 440px;

    &.half {
      width: 220px;
    }
  }

  .status-container {
    width: 880px;
  }

  .error-bag {
    margin-top: 8px;
    max-width: 440px;
  }

  .field-description {
    margin-top: $qm-margin-xs;
    max-width: 440px;
  }

  .description-textarea-container {
    .qm-textarea {
      height: 200px;
    }
  }

  .tabs-container {
    margin-bottom: $qm-padding-m;
    border-bottom: 1px solid $qm-gray-lighter;
    padding: 0;
    display: inline-block;

    .tabs-item {
      margin: 0;
      padding: $qm-margin-xs $qm-padding-ms;
    }
  }

  .questions-subtitle {
    max-width: 50ch;
    color: $qm-color-text-secondary;
    font-family: MaisonNeue-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: $qm-padding;
    margin-top: $qm-margin-xs;

    .qm-button {
      margin-top: $qm-padding-ms;
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .aql-container {
    > .flex > * + * {
      margin-left: 8px;
    }
    .aql-major,
    .aql-minor,
    .aql-functional,
    .aql-level {
      width: 155px;
    }
  }

  .defects-container {
    .input-info {
      display: none;
    }
    .critical-defects-input {
      width: 72px;
    }

    .critical-defects-switch {
      margin-left: 8px;
    }
  }

  .validity-container {
    .qm-input-container {
      width: 72px;
      margin-right: $qm-margin-xs;

      .input-info {
        display: none;
      }
    }

    .select-input {
      flex: 1;
    }
  }

  .question-groups-container {
    margin-bottom: $qm-padding;

    & > * + * {
      margin-top: $qm-margin-m;
    }

    .header-actions {
      display: flex;
      align-items: center;
      gap: $qm-margin-m;
    }
  }
}
