@import '~styles/variables/colors';

.timeline {
  h4 {
    font-family: 'MaisonNeue-Medium';
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 32px;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  &.primary {
    ul.timeline-subitem {
      &::after {
        background: $qm-color-text-link;
      }

      li {
        &::before {
          background: $qm-color-text-link;
        }

        .subitem-icon {
          background: $qm-color-text-link;
          &.cutsom-icon {
            background: $qm-color-text-link;
          }
        }
      }
    }
  }
}
