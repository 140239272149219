@import "~styles/variables/colors";
@import "~styles/variables/sizes";

.inspections-table {
  .table-cell {
    .qm-third-party-pill {
      display: inline-flex;
      margin-left: $qm-margin-xs;
    }

    &.id-cell {
      font-family: "MaisonNeue-Book";
      font-size: 14px;
      font-weight: 300;
      color: $qm-color-text-secondary;
      white-space: nowrap;

      span {
        line-height: 14px;
        display: inline-block;
      }

      .no-wrap {
        white-space: nowrap;
      }

      .qm-warning-pill-container {
        line-height: 24px;
        margin-right: 8px;
      }
    }

    &.time-per-unit-cell {
      display: none;
    }

    &.select-cell {
      display: none;
      cursor: initial;
      width: 0;

      padding: 0;

      .checkbox-wrapper {
        padding: $qm-padding;
        padding-left: $qm-padding-m;
      }

      .qm-checkbox {
        padding: $qm-padding-s;
        margin-left: -$qm-padding-s;
        width: $qm-input-height;
      }
    }

    .primary-text {
      color: rgb(22, 22, 22);
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 22px;
      max-width: 65ch;
    }

    .secondary-text {
      color: $qm-color-text-secondary;
      font-family: MaisonNeue-Book;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.16px;
      line-height: 18px;

      &.reinspecton-label {
        margin-top: 0.25em;
        .pill {
          font-size: 0.75em;
        }
      }
    }

    .unassigned {
      color: $qm-color-text-secondary;
      font-family: MaisonNeue-Medium;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 22px;
    }

    &.align-right {
      .pill-container {
        justify-content: flex-end;
      }
    }

    &.align-left {
      .pill-container {
        justify-content: flex-start;
      }
    }

    .pill-container {
      display: flex;
      align-items: baseline;
    }
  }

  &.selectable {
    .table-cell.select-cell {
      display: table-cell;
    }
  }

  &.show-time-per-unit {
    .table-cell.time-per-unit-cell {
      display: table-cell;
    }
  }

  &.hide-result {
    .table-cell.result-cell {
      display: none;
    }
  }

  .table-header {
    .table-cell.select-cell {
      padding-bottom: 0;
      padding-top: 0;
      padding-left: $qm-padding-m;
      padding-right: $qm-padding;

      .qm-checkbox {
        padding: 0;
        justify-content: center;
      }
    }
  }

  .table-row {
    &.row-selected {
      background: $qm-color-row-selected;
    }
  }
}
