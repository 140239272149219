@import '~styles/variables/colors';

.sources-table {
  .table-cell {
    &.id-cell {
      font-family: 'MaisonNeue-Book';
      font-size: 14px;
      font-weight: 300;
      color: $qm-color-text-secondary;
      white-space: nowrap;

      span {
        line-height: 14px;
        display: inline-block;
      }

      .qm-warning-pill-container {
        line-height: 24px;
        margin-right: 8px;
      }
    }

    &.align-right {
      .pill-container {
        justify-content: flex-end;
      }
    }

    &.align-left {
      .pill-container {
        justify-content: flex-start;
      }
    }

    .pill-container {
      display: flex;

      .status-pill {
        white-space: nowrap;
      }
    }
  }
}
