@import 'src/styles/variables/_colors';
@import 'src/styles/variables/_sizes';

.qm-document-upload {
  .form-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .document-upload-actions {
    display: flex;
    gap: $qm-padding;
  }

  .document-item {
    margin-bottom: $qm-margin-s;

    .qm-file {
      flex-direction: row;
    }

    .input-info {
      margin-bottom: 0;
    }

    .delete-custom-field {
      margin-left: $qm-padding-ms;
    }
  }
}
