@import 'src/styles/variables/colors';
@import 'src/styles/variables/sizes';

.qm-import-result {
  > div {
    margin: 40px 0;
  }

  .result-icon {
    display: inline-block;
    padding: 11px 10px 9px 14px;
    border-radius: 100%;
    margin-right: $qm-margin-s;
  }

  .success {
    color: #24a148;
    font-size: 20px;

    .result-icon {
      background-color: #42be65;
    }
  }

  .file-download {
    a {
      text-decoration: none;
      display: inline-block;
    }

    .qm-file {
      flex-direction: row;
      gap: $qm-margin-s;

      .name {
        margin-top: 0;
      }
    }
  }

  .error {
    .result-icon {
      background-color: $qm-color-delete;
      vertical-align: top;
    }
    .messages {
      display: inline-block;
      width: 100%;
      max-width: calc(100% - 70px);

      p:first-child {
        color: $qm-color-error;
        font-size: 20px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 12px;
      }

      p {
        color: $qm-color-text-secondary;
        max-width: 100%;
      }
    }
  }
}
