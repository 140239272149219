@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.qm-info-box {
  font-family: MaisonNeue-Book;
  font-weight: 300;
  padding: $qm-padding-ms;
  display: flex;
  flex-direction: row;

  border-radius: $qm-border-radius-m;
  border-width: 1px;
  border-style: solid;

  .icon {
    margin-right: $qm-padding-ms;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  .body {
    white-space: pre-wrap;
    line-height: 1.125em;
    font-size: 14px;
  }

  &.success {
    background-color: $qm-color-success;
    color: #044317;
    border-color: #044317;

    .icon {
      background-color: darken($qm-color-success, 10%);
    }
  }

  &.error {
    background-color: #ffd7d9;
    color: #750e13;
    border-color: #750e13;

    .icon {
      background-color: darken(#ffd7d9, 10%);
    }
  }

  &.warning {
    background-color: #f1c21b;
    color: #3d3927;
    border-color: #3d3927;

    .icon {
      background-color: darken(#f1c21b, 10%);
    }
  }

  &.notify {
    background-color: #e0e0e0;
    color: #510224;
    border-color: #510224;

    .icon {
      background-color: darken(#e0e0e0, 10%);
    }
  }

  &.info {
    background-color: rgba(69, 128, 255, 0.1);
    color: rgb(69, 128, 255);
    border-color: rgb(69, 128, 255);

    .icon {
      background-color: rgba(69, 128, 255, 0.1);
    }
  }
}
